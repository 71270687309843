const Configuration = {
  options: {
    hasCheckbox: true,
    hasEdit: true,
    hasDelete: true,
  },
  fields: [
    {
      path: 'name',
      field: 'Nome',
      type: 'text',
    },
    {
      identifier: 'updated_at',
      field: 'Atualizado',
      type: 'date-time',
      path: 'updated_at',
    },
  ],
};

export default Configuration;
