import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default class TextEditor extends React.Component {
  handleEditorChange = (content, editor) => {
    this.props.onChange ? this.props.onChange(this.props.id, content) :
      console.log('Content was updated:', editor, content);
  }

  render() {
    return (
      <Editor
        apiKey="f1c5ek47b5rmmqii1bnwupze0svazvkigiee299978m58edf"
        initialValue={this.props.defaultValue ? this.props.defaultValue : ""}
        init={{
          height: 200,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
        }
        }
        onEditorChange={this.handleEditorChange}
      />
    );
  }
}
