import React, {useState, useEffect} from 'react'
import {
  Header,
  Sidebar,
  Breadcrumb,
  NotifyBox,
  Footer,
  Loading
} from '../../components';
import DynamicForm from '../../components/form-dynamic-v2'
import Sdk from 'api.digitalpages.module.sdk.api';

export default function NewEntity({consumer, history, match, location}) {
  const [entity, setEntity] = useState(null);
  const [entityUpd, setEntityUpd] = useState(null);
  const [canAddEntity, setCanAddEntity] = useState(null);
  const [ids, setIds] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');
  const [loadedProperties, setLoadedProperties] = useState(false)
  useEffect(() => {
    getFormFields()
  }, [])
  useEffect(() => {
    if(!loadedProperties && entity) loadProperties()
  },[entity])
  const getFormFields = async () => {
    const response = await fetch('./preferences.json');

    const { forms } = await response.json();
    setEntity(forms['new-entity']);

    // consumer.defineRoute({
    //   name: forms['new-entity'].pageTitle
    // });
  }
  const loadProperties = async () => {
    const schema = await Sdk.Api.authorization.entity.schema(match.params.schemaId)
    if (!schema) {
      setLoadedProperties(true)
      return
    }
    const {properties} = schema
    const entityCp = {...entity}
    const propertiesField = entityCp.fields.find(field => field.type === 'input-entity-properties')
    propertiesField.options = properties
    setEntity(entityCp)
    setLoadedProperties(true)
  }
  const handleSubmit = async (form) => {
    setLoadingSubmit(true)
    try {
      const propertiesField = entity.fields.find(field => field.type === 'input-entity-properties')
      if (!propertiesField) return

      const searchParams = new URLSearchParams(location.search)
      const row = searchParams.get('row')
      const column =  searchParams.get('column')
    
      const entityDto = {
        row,
        column,
        data: {

        }
      }
      propertiesField.options.forEach(property => {
        entityDto.data[property.name] = form['property-' + property.name]
      })
      await Sdk.dynamic.bridge(`auth/v1.0/entity/management/schema/uid/${match.params.schemaId}`, entityDto, 'POST')
      setMsgSuccess('Entidade criada com sucesso!')
    } catch (error) {
      setMsgError('Algo deu errado, tente novamente mais tarde...')
    }
    setLoadingSubmit(false)
  }
  return (
    <>
      {entity ? (
        <div className="rdp-admin-new-entity">
          <Header />
          <Sidebar
            currentRoute="/users/new"
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
          <div id="rdp-admin-content-area" className="rdp-admin-content">
            {loadingSubmit && <Loading msg="Criando Esquema..." />}
            {!loadingSubmit && msgSuccess && (
              <NotifyBox
                type="success"
                onClose={() => setMsgSuccess('')}
                message={msgSuccess}
              />
            )}
            {!loadingSubmit && msgError && (
              <NotifyBox
                type="error"
                onClose={() => setMsgError('')}
                message={msgError}
              />
            )}
            <div className="test">
              {entity && (
                <DynamicForm
                  handleSubmit={handleSubmit}
                  form={entityUpd ? entityUpd : entity}
                  submitText={entity.submitText}
                  // defaultValues={defaultValues}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading msg="Montando Formulário..." />
      )}
      <Footer />
    </>
  )
}
