import React, { Component } from 'react';
import './list-content.scss';

import {
  Header,
  Sidebar,
  Breadcrumb,
  TableDynamic,
  HeaderFilter,
  Pagination,
  NotifyBox
} from '../../components';
import { Redirect } from 'react-router-dom';
import { IconConstruct, IconNone } from '../../components/icon';

import NoticesService from '../../services/notices'
import { NoticeService } from '../../services/notice'
import { UserService } from '../../services/user';
import Loading from '../../components/loading';
import RDP_UTILS from '../../config/util';
import Sdk from 'api.digitalpages.module.sdk.api';

export default class ViewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_preloader: true,
      loadingSubmit: null,
      msgSuccess: null,
      msgError: null,
      redirectTo: null,
      options: {
        hasCheckbox: true,
        hasEdit: true,
        hasDelete: true,
      },
      filters: {
        order_field: 'created_at',
        order_type: 'desc',
        status: '',
        start_at: '',
        finish_at: '',
        text: '',
        page: 1,
      },
      body: [],
      seletedElementToDelete: [],
      groups : [],
      currentGroup : null,
      currentRoute : null,
      elements: [],
      defaultFields: [
        {
          identifier: 'status',
          field: 'Status',
          type: 'status',
          path : 'status'
        },
        {
          identifier: 'created_at',
          field: 'Criado',
          type: 'date-time',
          path : 'created_at'
        },
        {
          identifier: 'updated_at',
          field: 'Atualizado',
          type: 'date-time',
          path : 'updated_at'
        }
      ]
    }
    
  }

  componentDidMount() {

    this.getGroups();
  }

  async componentDidUpdate(prevProps, prevState) 
  {  
    if (this.props.match.params.routeUid === prevProps.match.params.routeUid && 
      this.props.match.params.groupUid === prevProps.match.params.groupUid)  return;

    let activeGroup = null;

    if (this.props.match.params.groupUid)
    {
      let group = this.state.groups.find(p=> p.uid == this.props.match.params.groupUid);

      if (group) 
      {
        activeGroup = group;
      }
    }

    // this.state.groups.forEach(group => {
    //   let route = group.routes.find(p=> p.uid == this.props.match.params.routeUid);

    //   if (route !== null) 
    //   {
    //     activeGroup = group;
    //     activeRoute = route;
    //   }
    // });

    this.setGroup(activeGroup);//, activeRoute);
  }

  async getGroups()
  {
    const result = await Sdk.cms.news.getGroups();
    let activeGroup = result.result[0];
    let activeRoute = null;

    if (this.props.match.params.groupUid)
    {
      let group = result.result.find(p=> p.uid == this.props.match.params.groupUid);

      if (group) 
      {
        activeGroup = group;
      }
    }

    if (this.props.match.params.routeUid)
    {
      result.result.forEach(group => {
        let route = group.routes.find(p=> p.uid == this.props.match.params.routeUid);

        if (route) 
        {
          activeGroup = group;
          activeRoute = route;
        }
      });
    }

    this.setState({ groups : result.result }, () => this.setGroup(activeGroup, activeRoute));
  }

  setGroup (group, route) 
  {
    let firstRoute = route;

    if (!route && group.routes.length > 0){
      firstRoute = group.routes[0];
    }

    this.setState({ currentGroup : group, currentRoute: firstRoute, page: 1}, () => this.getNotices());
  }

  getNotices = () => {
    const { filters, currentRoute } = this.state;
    var _this = this;

    if (currentRoute == null) return;

    this.setState({
      show_preloader: true
    });
    // console.log("PROPS", this.props);
    // const { consumer } = this.props;
    // const routeUid = consumer && consumer.currentRoute ? consumer.currentRoute.uid : null;

    NoticesService.getNotices(currentRoute.uid, filters).then(result => {
      if (result) {
        _this.setState({
          elements: result,
          msgNoContent: result.row_count < 1 ? "Não foi possível encontrar os dados." : null,
          show_preloader: false
        });
      } else {
        _this.setState({
          elements: [],
          msgNoContent: "Não foi possível encontrar os dados.",
          show_preloader: false
        });
      }
    }).catch(() => {
      _this.setState({
        show_preloader: false,
        noContent: true
      })
    });
  }

  async deleteEntity(noticeId) {
    let removeResult = await NoticeService.deleteNotice(noticeId);
    if (!removeResult.response) {
      this.setState({
        loadingSubmit: null,
        msgSuccess: this.props.consumer.currentRoute.name + " foi excluído com sucesso!",
      }, this.getNotices);
    } else {
      this.setState({
        seletedElementToDelete: [],
        loadingSubmit: null,
        msgSuccess: null,
        msgError: "Ocorreu um erro ao tentar excluir."
      });
    }
  }

  async deleteMultiEntities(ids) {
    let removeResult = await NoticeService.deleteMultiNotices(ids);
    if (!removeResult.response) {
      this.setState({
        loadingSubmit: null,
        seletedElementToDelete: [],
        msgSuccess: this.props.consumer.currentRoute.name + " foram excluídos com sucesso!",
        msgError: null
      }, this.getNotices);
    } else {
      this.setState({
        seletedElementToDelete: [],
        loadingSubmit: null,
        msgSuccess: null,
        msgError: "Ocorreu um erro ao tentar excluir."
      });
    }
  }

  onFilterChange(column) {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...this.state.filters,
        order_field: column.identifier,
        order_type: filters.order_type === 'desc' ? 'asc' : 'desc',
        page: 1
      }
    }, () => {
      this.getNotices();
    });
  }

  onChangeEntityValue(target) {
    if (!target) {
      this.setState({
        entityOptionFilter: null
      }, () => {
        this.setState({
          filters: {
            ...this.state.filters,
            filter: undefined,
            page: 1
          }
        }, () => {
          this.getNotices();
        });
      });
      return;
    }

    this.setState({
      entityOptionFilter: target.value
    }, () => {
      this.setState({
        filters: {
          ...this.state.filters,
          filter: `(${target.identifier}:${target.value})`,
          page: 1
        }
      }, () => {
        this.getNotices();
      });
    });
  }

  onFilterStatusChange(status) {
    this.setState({
      filters: {
        ...this.state.filters,
        status: status.value,
        page: 1
      }
    }, () => {
      this.getNotices();
    });
  }

  onFilterDataChange(start, finish) {
    this.setState({
      filters: {
        ...this.state.filters,
        start_at: start,
        finish_at: finish,
        page: 1,
      }
    }, () => {
      this.getNotices();
    });
  }

  onFilterTextChange(text) {

    this.setState({
      filters: {
        ...this.state.filters,
        text,
        page: 1
      }
    }, () => {
      this.getNotices();
    });
  }

  redirectToNew = () => {
    const { currentRoute, } = this.state;
    return this.setState({ redirectTo: `/cms/news/route/uid/${currentRoute.uid}/entity/uid/${currentRoute.entity_uid}` });
  }

  redirectToUpdate = (noticeId) => {
    const { route, group } = this.props.match.params;
    return this.setState({ redirectTo: `/edit-conteudo/${group}/${route}/registro/${noticeId}` });
  }

  handleOptionSelected = (option) =>
  {
    if (option.type == "group")
    {
      this.setGroup(option.data);
    }else{
      this.setState({currentRoute:option.data}, ()=> this.getNotices());
      
    }
  }

  handleCheckBoxClick(elementId) {
    const { seletedElementToDelete } = this.state;
    const findIndex = seletedElementToDelete.findIndex(element => element === elementId);

    if (findIndex >= 0) {
      seletedElementToDelete.splice(findIndex, 1);
    } else {
      seletedElementToDelete.push(elementId);
    }

    this.setState({
      seletedElementToDelete
    });
  }

  handleCheckBoxSelectAll(action, elementId) {
    if (action === 'add-check') {
      this.setState({
        seletedElementToDelete: elementId
      });
    } else {
      this.setState({
        seletedElementToDelete: []
      });
    }
  }

  handlePaginationClick(page) {
    this.setState({
      filters: {
        ...this.state.filters,
        page: page,
      }
    }, () => {
      this.getNotices(this.state.currentRoute);
    });
  }

  handleDelete = (id) => {
    this.setState({
      deleteModal: false,
      loadingSubmit: "Apagando dados..."
    }, () => {
      this.deleteEntity(id)
    });
  }

  handleMultiDelete = (id) => {
    this.setState({
      deleteModal: false,
      loadingSubmit: "Apagando dados..."
    }, () => {
      this.deleteMultiEntities(this.state.seletedElementToDelete)
    });
  }

  resetMsg = () => {
    this.setState({ msgSuccess: null, msgError: null });
  }

  getCounter(elements, filters) {
    return ((filters.page - 1) * elements.page_size) + (elements.result ? elements.result.length : 0);
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    const {
      filters,
      options,
      seletedElementToDelete,
      elements,
      defaultFields,
      show_preloader,
      msgSuccess,
      msgError,
      msgNoContent,
      loadingSubmit,
      noContent,
      groups,
      currentGroup,
      currentRoute
    } = this.state;

    const { entity } = this.props.match.params;
    const selectOptions = [
      // groups.map(group =>({
      //   label: group.name,
      //   value: group.identifier,
      //   active : group == currentGroup,
      //   data : group,
      //   type : "group"
      // })),
      currentGroup && currentGroup.routes.map(route =>({
        label: route.name,
        value: route.identifier,
        active : route == currentRoute,
        data : route,
        type : "route"
      }))
    ];

    return (
      <div className="rdp-admin-list-content">
        <Header />
        <Sidebar
          defineGroup={this.props.consumer ? this.props.consumer.defineGroup : null}
          defineRoute={this.props.consumer ? this.props.consumer.defineRoute : null}
          groups={this.props.consumer ? this.props.consumer.groups : null} />
        <Breadcrumb
          currentRoute={this.props.consumer ? this.props.consumer.currentRoute : null}
          currentGroup={this.props.consumer ? this.props.consumer.currentGroup : null}
          options = {selectOptions}
          optionSelected = {this.handleOptionSelected}
          newOption={this.redirectToNew} label={this.props.match.params.entity}
          customNewOptionTitle = {currentRoute ? `Adicionar em ${currentRoute.name}` : null} />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loadingSubmit && <Loading msg={loadingSubmit} />}
          {!show_preloader && msgSuccess && <NotifyBox type="success" onClose={this.resetMsg} message={msgSuccess} />}
          <>
            {!noContent && (
              <HeaderFilter
                entityOptionFilter={this.state.entityOptionFilter}
                configuration={elements.details}
                count={this.getCounter(elements, filters) || 0}
                total={elements.row_count}
                selectedToDelete={seletedElementToDelete}
                body={elements.result}
                onChangeEntityValue={(value) => this.onChangeEntityValue(value)}
                onStatusChange={(status) => this.onFilterStatusChange(status)}
                onDateChange={(start, finish) => this.onFilterDataChange(start, finish)}
                onTextChange={(text) => this.onFilterTextChange(text)}
                status={filters.status}
                start_at={filters.start_at}
                finish_at={filters.finish_at}
                text={filters.text}
                entityId={this.props.match.params.entityId}
                entity={entity}
                onDelete={this.handleMultiDelete}
              />
            )}
            {!show_preloader && noContent && (
              <div id="rdp-admin-content-area" className="rdp-admin-content">
                <div className="box-construction">
                  <div> <IconConstruct /> </div>
                  <p>SEM RESULTADOS.</p>
                </div>
              </div>
            )}
            {!show_preloader && msgError && <NotifyBox type="error" onClose={this.resetMsg} message={msgError} />}
            {!show_preloader && msgNoContent && (
              <div className="box-construction">
                <div> <IconNone /> </div>
                <p>SEM RESULTADOS.</p>
              </div>
            )}
            {show_preloader && <Loading msg="Carregando Conteúdo..." />}
            {!show_preloader && (
              <TableDynamic
                title={this.props.match.params.route}
                configuration={elements.details}
                headers={(elements.details.map(detail =>{
                  detail.path = `data.${detail.identifier}`;
                  return detail;
                }) || []).slice(0, 4)}
                defaultFields={defaultFields}
                data={elements.result ? elements.result : []}
                hasCheckbox={options.hasCheckbox}
                hasEdit={options.hasEdit}
                hasDelete={options.hasDelete}
                filters={filters}
                onFilterChange={(filter) => this.onFilterChange(filter)}
                onCheckboxClick={(elementId) => this.handleCheckBoxClick(elementId)}
                onCheckBoxCheckAll={(action, elementId) => this.handleCheckBoxSelectAll(action, elementId)}
                onUpdate={this.redirectToUpdate}
                onDelete={this.handleDelete}
              />
            )}
            <Pagination currentPage={filters.page} registerCount={elements.row_count} limit={elements.page_size} onPaginationButtonClick={(page) => this.handlePaginationClick(page)} />
          </>
        </div>
      </div >
    );
  }
}
