import SDK from 'api.digitalpages.module.sdk.api';

const NoticeService = function () {
  this.getNotices = async function (uid, filters) {
    return await SDK.Api.news.getNotices(
      uid,
      { ...filters, pageSize: 30 },
      { noCache: true },
    );
  };
};

export default new NoticeService();
