import React, { useState, useEffect } from 'react';
import { GenericList } from '../../builders/list';
import axios from 'axios';
import { Footer } from '../../components';
import './styles.scss';

export default function ListProfiles({ consumer, entity, history }) {
  const [elements, setElements] = useState([]);
  const [messages, setMessages] = useState({});
  const [config, setConfig] = useState({});
  const [preloader, setPreloader] = useState(true);

  async function configTable() {
    const response = await fetch('./preferences.json');

    const { tables } = await response.json();

    setConfig(tables.profiles);
  }

  async function getProfiles() {
    const { data } = await axios.get('http://localhost:3333/profiles');

    const tempStructure = {
      result: data,
      current_page: 1,
      page_count: 1,
      page_size: data.length,
      row_count: data.length,
    };

    setMessages({
      noContent: data.length == 0 ? 'No profile registered' : null,
    });

    setElements(tempStructure);
    setPreloader(false);
  }

  useEffect(() => {
    configTable();
    getProfiles();

    consumer.defineRoute({ name: 'Profiles', uri: '/profiles' });
  }, []);

  function handleMultiDelete(uid) {
    //DELETE PROFILE ENDPOINT
  }
  function onUpdate(uid) {
    history.push(`profiles/${uid}`);
  }

  return (
    <>
      <GenericList
        consumer={consumer}
        elements={elements}
        messages={messages}
        fields={config.fields}
        options={config.options}
        breadcrumb={{
          selectOptions: null,
          routeNew: '/profiles/new',
          label: entity,
          customNewOptionTitle: 'New Profile',
        }}
        loading={{
          preloader,
        }}
        actions={{
          // closeMessage: () => {
          //   setMessages(null);
          // },
          onUpdate: (uid) => onUpdate(uid),
          onDelete: (uid) => handleMultiDelete(uid),
        }}
      />
      <Footer />
    </>
  );
}
