import React from 'react';
import 'react-responsive-modal/styles.css';
import './modal-upload.scss';
import Modal from 'react-responsive-modal';
import { IconCloseModal, IconUpload } from '../icon';
import DropzoneUpload from '../dropzone-upload/dropzone-upload';

export default function ModalUpload({
  title, open, onClose, onUpload,
}) {
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      styles={{
        overlay: { backgroundColor: 'rgba(125, 125, 125, 0.2)' },
        modal: { borderRadius: '5px', padding: '0px' },
      }}
      showCloseIcon={false}
      focusTrapped={false}
      center
    >
      <div className="rdp-modal-upload-container">
        <div className="rdp-modal-container-header">
          <div className="header-left">
            <div className="icon">
              <IconUpload />
            </div>
            <span>Upload de Imagem</span>
          </div>
          <button
            type="button"
            className="rdp-modal-close"
            onClick={() => onClose()}
          >
            <IconCloseModal />
          </button>
        </div>
        <div className="rdp-modal-container-content">
          <DropzoneUpload
            subtitle="Selecione as imagens para upload..."
            accept=".jpg,.png"
            multiple
            id={1}
            onUpload={onUpload}
          />
        </div>
        <div className="rdp-modal-container-footer">
          <button
            className="rdp-modal-button-cancel margin-right"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
          <button className="rdp-modal-button-delete" onClick={() => onClose()}>
            Concluir
          </button>
        </div>
      </div>
    </Modal>
  );
}
