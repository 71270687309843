import React, { useState, useEffect, useCallback } from 'react';
import 'react-responsive-modal/styles.css';
import './table-field.scss';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IconEdit, IconTrash } from '../icon';

import ButtonDynamic from '../button-dynamic/button-dynamic';
import RDP_UTILS from '../../config/util';

const fieldsType = {
  INPUT_TEXT: 'input-text',
  INPUT_NUMBER: 'input-number',
  INPUT_COLOR: 'input-color',
  REFERENCE_ENTITY: 'reference-entity',
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  COLOR: 'color',
  DATE_TIME: 'date-time',
  STATUS: 'status',
  SELECT: 'select',
  MULTIPLE_SELECT: 'multiple-select',
  CHECKBOX: 'checkbox',
  IMAGE: 'image',

  TABLE: 'table',
  SWITCH: 'switch',
  WEBPACK: 'webpack',
};

export default function TableField({
  id,
  onChange,
  configuration,
  defaultData,
  triggerSelectImage,
  entity,
  selectOptions,
}) {
  const [countRows, setCountRows] = useState(0);
  const [rows, updateRows] = useState([{ row: 1 }]);

  useEffect(() => {
    if (defaultData && defaultData.length) {
      updateRows(
        defaultData.map((row, index) => {
          row.row = index + 1;
          return row;
        }),
      );
    }
  }, [defaultData]);

  const handleSelectChange = (row, identifier, target) => {
    if (Array.isArray(target)) {
      target = target.map((option) => option.value);
    } else if (target && target.value) {
      target = target.value;
    }

    handleChange(row, identifier, target);
  };

  const handleChange = useCallback(
    (iRow, identifier, value) => {
      const changed = rows.map((row) => {
        if (row.row === iRow) {
          row[identifier] = value;
          return row;
        }
        return row;
      });

      updateRows(changed);
      onChange(id, rows);
    },
    [rows],
  );

  const defaultInput = function (row, id, type, step = '0.01') {
    const value = row[id] ? row[id] : '';
    return (
      <div className="tf-fields">
        <input
          className="tf-input"
          key={`field-${id}`}
          step={step}
          id={`field-${id}`}
          value={value}
          name={id}
          type={type}
          onChange={(e) => handleChange(row.row, id, e.target.value)}
        />
      </div>
    );
  };

  const defaultImage = function (row, id) {
    const imageData = row[id] ? row[id] : '';

    let imageUrl = '';
    let typeImage = null;
    if (imageData) {
      imageUrl = RDP_UTILS.urlRDPImage(imageData.uid);
      typeImage = imageData.content_type ? imageData.content_type : null;
    }

    return (
      <div>
        <div
          onClick={() => triggerSelectImage((image) => handleChange(row.row, id, image))}
          className="tf-image-preview"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          {typeImage && (
            <span className="file-badge-type">
              {RDP_UTILS.typeFile(typeImage)}
            </span>
          )}
        </div>
      </div>
    );
  };

  const entitySelect = (row, identifier, index, options = {}) => {
    if (!entity || !entity.configuration) return null;

    const value = row[identifier] ? row[identifier] : '';

    let entityConfiguration = entity.configuration.find(
      ({ identifier: _identifier }) => _identifier === id,
    );

    if (!entityConfiguration) {
      return null;
    }

    entityConfiguration = entityConfiguration.configuration.columns.find(
      ({ identifier: _identifier }) => _identifier === identifier,
    );

    if (!selectOptions) {
      return null;
    }

    const result = selectOptions.find(
      ({ identifier: _identifier }) => _identifier == [id, identifier].join('->'),
    );
    let data = [];

    if (result) {
      data = result.data;
    }

    const hasColor = () => entityConfiguration.reference_entity_properties.options_fields.some(
      ({ type }) => [fieldsType.INPUT_COLOR].includes(type),
    );

    const getColor = ({ data }) => {
      const field = entityConfiguration.reference_entity_properties.options_fields.find(
        ({ type }) => [fieldsType.INPUT_COLOR].includes(type),
      );

      if (!field) return;

      return data[field.identifier];
    };

    const getLabel = ({ data }, configuration) => (
      configuration || entityConfiguration
    ).reference_entity_properties.options_fields
      .filter(({ type }) => [fieldsType.INPUT_TEXT].includes(type))
      .map(({ identifier }) => (data ? data[identifier] : undefined))
      .join(',');

    const optionEntities = entityConfiguration.reference_entity_properties.options_fields.filter(
      ({ type }) => [fieldsType.REFERENCE_ENTITY].includes(type),
    );

    const optionFields = entityConfiguration.reference_entity_properties.options_fields.find(
      ({ type }) => [fieldsType.INPUT_TEXT].includes(type),
    );

    const defaultValue = () => {
      if (!value || !data || !data.length) return null;

      if (options.multiple) {
        return data
          .filter(({ uid }) => value.includes(uid))
          .map((option) => ({
            value: option.uid,
            label: getLabel(option),
            color: getColor(option),
          }));
      }

      return data.find(({ uid }) => uid === value)
        ? {
          value,
          label: getLabel(data.find(({ uid }) => uid === value)),
          color: getColor(data.find(({ uid }) => uid === value)),
        }
        : null;
    };

    return (
      <>
        {optionFields && (
          <td key={index}>
            <div className="tf-fields">
              <Select
                placeholder="Selecione"
                onChange={(target) => handleSelectChange(row.row, identifier, target)}
                isMulti={!!options.multiple}
                isClearable
                isDisabled={false}
                options={
                  data && data.length
                    ? data.map(({ uid, ...option }) => ({
                      value: uid,
                      label: getLabel(option),
                      color: getColor(option),
                    }))
                    : []
                }
                value={defaultValue()}
                defaultValue={defaultValue()}
                styles={
                  hasColor()
                    ? RDP_UTILS.ColorSelectStyle
                    : RDP_UTILS.MultipleSelect.styleForm
                }
                isSearchable={false}
                theme={RDP_UTILS.MultipleSelect.theme}
              />
            </div>
          </td>
        )}
        {optionEntities.map((optionEntity, index) => (
          <td key={index}>
            <div className="tf-fields">
              <Select
                placeholder="Selecione"
                onChange={(target) => handleSelectChange(row.row, identifier, target)}
                isMulti={false}
                isClearable
                isDisabled={!!index}
                options={
                  data && data.length
                    ? data.map(({ uid, ...option }) => ({
                      value: uid,
                      label: getLabel(
                        option.data[optionEntity.identifier],
                        optionEntity,
                      ),
                      color: getColor(
                        option.data[optionEntity.identifier],
                        optionEntity,
                      ),
                    }))
                    : []
                }
                value={defaultValue()}
                defaultValue={defaultValue()}
                styles={
                  hasColor()
                    ? RDP_UTILS.ColorSelectStyle
                    : RDP_UTILS.MultipleSelect.styleForm
                }
                isSearchable={false}
                theme={RDP_UTILS.MultipleSelect.theme}
              />
            </div>
          </td>
        ))}
      </>
    );
  };

  const renderFields = function (row, columns) {
    const fields = [];
    columns.map((field, index) => {
      switch (field.type) {
        case 'image':
          fields.push(
            <td key={index}>{defaultImage(row, field.identifier)}</td>,
          );
          break;
        case 'reference-entity':
          fields.push(
            entitySelect(row, field.identifier, field.type, field.options),
          );
          break;

        default:
          fields.push(
            <td key={index}>
              {defaultInput(row, field.identifier, field.type)}
            </td>,
          );
      }
    });

    return fields;
  };

  const renderBody = function () {
    return (
      <Droppable type="row" droppableId="droppable-rows">
        {(provided, snapshot) => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            {rows.map((row, index) => (
              <Draggable
                id={row.row}
                key={row.row}
                index={index}
                draggableId={JSON.stringify({ row: row.row })}
              >
                {(_provided, snapshot) => (
                  <tr
                    ref={_provided.innerRef}
                    {..._provided.draggableProps}
                    key={index}
                    className="table-field-row"
                  >
                    {!configuration.hidden_move && (
                      <td
                        {..._provided.dragHandleProps}
                        className="n-row--move"
                      />
                    )}
                    {!configuration.hidden_index && (
                      <td className="n-row">{row.row}</td>
                    )}
                    {renderFields(row, configuration.columns)}
                    <td className="n-row">
                      <Link to="#!" onClick={() => deleteRow(row.row)}>
                        <IconTrash />
                      </Link>
                    </td>
                  </tr>
                )}
              </Draggable>
            ))}
          </tbody>
        )}
      </Droppable>
    );
  };

  const newRow = () => {
    const change = rows;
    change.push({ row: rows.length + 1 });
    setCountRows(change.length);
    return updateRows(change);
  };

  const deleteRow = (iRow) => {
    let count = 1;
    const change = rows.filter((row) => {
      if (row.row !== iRow) {
        row.row = count;
        count++;
        return row;
      }
    });
    setCountRows(change.length);
    return updateRows(change);
  };

  const moveRow = (source, destination) => {
    const change = [...rows];
    const currentDest = { ...change[destination] };
    const currentSrc = { ...change[source] };
    change[destination].row = currentSrc.row;
    change[source].row = currentDest.row;
    change.sort((a, b) => (a.row > b.row ? 1 : -1));
    setCountRows(change.length);
    return updateRows(change);
  };

  return (
    <div className="table-field-content">
      <DragDropContext
        onDragEnd={(target) => {
          if (target.source && target.destination) {
            moveRow(target.source.index, target.destination.index);
          }
        }}
      >
        <table>
          <thead>
            <tr>
              {!configuration.hidden_move && <th />}
              {!configuration.hidden_index && <th>#</th>}
              {configuration.columns
                .reduce((acc, current, index) => {
                  if (
                    current.reference_entity_properties
                    && current.reference_entity_properties.options_fields.find(
                      ({ type }) => type == 'reference-entity',
                    )
                  ) {
                    return (Array.isArray(acc) ? acc : [acc]).concat(
                      current.reference_entity_properties.options_fields.filter(
                        ({ type }) => type == 'reference-entity',
                      ),
                    );
                  }
                  return (Array.isArray(acc) ? acc : [acc]).concat(current);
                })
                .map((field, index) => (
                  <th key={index}>{field.field}</th>
                ))}
              <th />
            </tr>
          </thead>
          {renderBody()}
        </table>
      </DragDropContext>
      <div className="table-field-buttons">
        <ButtonDynamic
          disabled={false}
          color="black"
          size="fit"
          icon="plus"
          actionClick={() => newRow()}
          iconDiv
        >
          Nova Linha
        </ButtonDynamic>
      </div>
    </div>
  );
}
