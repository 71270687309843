import React, { useState, useEffect } from 'react';
import './notify-box.scss';
import { IconCloseModal } from '../icon';

const NotifyBox = ({ message, type, onClose }) => {
  const [msg, setMessage] = useState('Aviso!');
  const [typeMsg, setType] = useState('notify-box notify-success');

  useEffect(() => {
    setMessage(message);
    setType(`notify-box notify-box-${type}`);
  });

  return (
    <p className={typeMsg}>
      {msg}
      {onClose && (
        <span onClick={() => onClose()}>
          <IconCloseModal />
        </span>
      )}
    </p>
  );
};

export default NotifyBox;
