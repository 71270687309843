import { textsEN } from './language/en_us';
import { textsPT } from './language/pt_br';

var currentLanguage = window.localStorage.getItem(
  'rdp-manager-current-language'
);

export function setLanguage(language) {
  currentLanguage = language;

  console.log('currentLanguage', currentLanguage);
  window.localStorage.setItem('rdp-manager-current-language', language);
}

function currentTexts() {
  switch (currentLanguage) {
    case 'en_us': {
      return textsEN;
    }

    default: {
      return textsPT;
    }
  }
}

export const textFromUid = (uid) => {
  // return uid;x
  var texts = currentTexts();

  var info = texts[uid];

  if (!info) {
    return uid;
    // texts[uid] = uid;
    // info = uid;
  }

  // console.log('texts', texts);
  return info;

  // texts[uid] = {
  //   ptbr: '',
  //   enus: '',
  // };

  // return uid;
};
