import SDK from 'api.digitalpages.module.sdk.api';

const noticeService = function () {
  this.getNotice = async function (noticeId) {
    return await SDK.Api.news.getNoticeDetails(noticeId, true, {
      noCache: true,
    });
  };

  this.saveNotice = async function (routeId, data) {
    return await SDK.Api.news.saveNotice(routeId, data);
  };

  this.updateNotice = async function (noticeId, data) {
    return await SDK.Api.news.updateNotice(noticeId, data);
  };

  this.deleteNotice = async function (noticeId) {
    return await SDK.Api.news.deleteNotice(noticeId);
  };

  this.deleteMultiNotices = async function (ids) {
    return await SDK.Api.news.deleteMultipleNotices(ids);
  };
};

const NoticeService = new noticeService();

export { NoticeService };
