import React, { Component } from 'react';
import './list-groups.scss';

import {
  Header,
  Sidebar,
  Breadcrumb,
  TableDynamic,
  HeaderFilter,
  Pagination,
  NotifyBox,
} from '../../components';
import { Redirect } from 'react-router-dom';
import { IconConstruct, IconNone } from '../../components/icon';

import { NoticeService } from '../../services/notice';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';

export default class ViewProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_preloader: true,
      loadingSubmit: null,
      msgSuccess: null,
      msgError: null,
      redirectTo: null,
      options: {
        hasCheckbox: false,
        hasEdit: true,
        hasDelete: false,
      },
      filters: {
        page: 1,
      },
      body: [],
      seletedElementToDelete: [],
      elements: {},
      defaultFields: [
        {
          path: 'name',
          field: 'Nome',
          type: 'text',
        },
        {
          identifier: 'updated_at',
          field: 'Atualizado',
          type: 'date-time',
          path: 'updated_at',
        },
      ],
    };

    this.deleteProduct = this.deleteProduct.bind(this);
    this.deleteProducts = this.deleteProducts.bind(this);
  }

  componentDidMount() {
    this.getGroups();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.entityId !== prevProps.match.params.entityId) {
      this.setState(
        {
          filters: {
            order_field: 'created_at',
            order_type: 'desc',
            status: '',
            start_at: '',
            finish_at: '',
            text: '',
            page: 1,
          },
        },
        () => {
          this.getNotices();
        }
      );
      this.setState({
        seletedElementToDelete: [],
      });
    }
  }

  async getGroups() {
    this.setState({ show_preloader: true });

    const result = await Sdk.authorization.entity.entitySchemasAvailableInProject();
    //serviço ainda não paginado
    const tempStructure = {
      result: result,
      current_page: 1,
      page_count: 1,
      page_size: result.length,
      row_count: result.length,
      msgNoContent: result.length == 0 ? 'Nenhum grupo cadastrado' : null,
    };

    this.setState({
      elements: tempStructure,
      show_preloader: false,
      msgNoContent: result.length == 0 ? 'Nenhum produto cadastrado' : null,
    });
  }

  redirectToNew = () => {
    return this.setState({ redirectTo: `/store/product/new` });
  };

  redirectToUpdate = (productUid) => {
    return this.setState({ redirectTo: `/management/groups/${productUid}` });
  };

  handleCheckBoxClick(elementId) {
    const { seletedElementToDelete } = this.state;
    const findIndex = seletedElementToDelete.findIndex(
      (element) => element === elementId
    );

    if (findIndex >= 0) {
      seletedElementToDelete.splice(findIndex, 1);
    } else {
      seletedElementToDelete.push(elementId);
    }

    this.setState({
      seletedElementToDelete,
    });
  }

  handleCheckBoxSelectAll(action, elementId) {
    if (action === 'add-check') {
      this.setState({
        seletedElementToDelete: elementId,
      });
    } else {
      this.setState({
        seletedElementToDelete: [],
      });
    }
  }

  handleMultiDelete = (id) => {
    this.setState(
      {
        deleteModal: false,
        loadingSubmit: 'Apagando dados...',
      },
      () => {
        this.deleteProducts(this.state.seletedElementToDelete);
      }
    );
  };

  async deleteProduct(uid) {
    return await this.deleteProducts([uid]);
  }

  async deleteProducts(uids) {
    const products = this.state.elements.result;

    for (const index in uids) {
      const uid = uids[index];
      const product = products.find((p) => p.uid == uid);

      if (!product) continue;

      await Sdk.store.deleteProduct(product);
    }

    this.setState(
      {
        loadingSubmit: null,
        seletedElementToDelete: [],
        msgSuccess: 'Produto(s) removido(s)',
        msgError: null,
      },
      this.getGroups
    );
  }

  resetMsg = () => {
    this.setState({ msgSuccess: null, msgError: null });
  };

  getCounter(elements, filters) {
    return (
      (filters.page - 1) * elements.page_size +
      (elements.result ? elements.result.length : 0)
    );
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    const {
      filters,
      options,
      seletedElementToDelete,
      elements,
      defaultFields,
      show_preloader,
      msgSuccess,
      msgError,
      msgNoContent,
      loadingSubmit,
      noContent,
    } = this.state;

    const { entity } = this.props.match.params;
    const selectOptions = null;

    return (
      <div className="rdp-admin-list-content">
        <Header />
        <Sidebar
          defineGroup={
            this.props.consumer ? this.props.consumer.defineGroup : null
          }
          defineRoute={
            this.props.consumer ? this.props.consumer.defineRoute : null
          }
          groups={this.props.consumer ? this.props.consumer.groups : null}
        />
        <Breadcrumb
          currentRoute={
            this.props.consumer ? this.props.consumer.currentRoute : null
          }
          currentGroup={
            this.props.consumer ? this.props.consumer.currentGroup : null
          }
          options={selectOptions}
          optionSelected={this.handleOptionSelected}
          newOption={false}
          label={this.props.match.params.entity}
          customNewOptionTitle="Criar produto"
        />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loadingSubmit && <Loading msg={loadingSubmit} />}
          {!show_preloader && msgSuccess && (
            <NotifyBox
              type="success"
              onClose={this.resetMsg}
              message={msgSuccess}
            />
          )}
          <>
            {!noContent && (
              <HeaderFilter
                entityOptionFilter={this.state.entityOptionFilter}
                configuration={elements.details}
                count={this.getCounter(elements, filters) || 0}
                total={elements.row_count}
                selectedToDelete={seletedElementToDelete}
                body={elements.result}
                onChangeEntityValue={(value) => this.onChangeEntityValue(value)}
                // onStatusChange={(status) => this.onFilterStatusChange(status)}
                // onDateChange={(start, finish) => this.onFilterDataChange(start, finish)}
                // onTextChange={(text) => this.onFilterTextChange(text)}
                // status={filters.status}
                // start_at={filters.start_at}
                // finish_at={filters.finish_at}
                text={filters.text}
                entityId={this.props.match.params.entityId}
                entity={entity}
                onDelete={this.handleMultiDelete}
              />
            )}
            {!show_preloader && noContent && (
              <div id="rdp-admin-content-area" className="rdp-admin-content">
                <div className="box-construction">
                  <div>
                    {' '}
                    <IconConstruct />{' '}
                  </div>
                  <p>SEM RESULTADOS.</p>
                </div>
              </div>
            )}
            {!show_preloader && msgError && (
              <NotifyBox
                type="error"
                onClose={this.resetMsg}
                message={msgError}
              />
            )}
            {!show_preloader && msgNoContent && (
              <div className="box-construction">
                <div>
                  {' '}
                  <IconNone />{' '}
                </div>
                <p>SEM RESULTADOS.</p>
              </div>
            )}
            {show_preloader && <Loading msg="Carregando Conteúdo..." />}
            {!show_preloader && (
              <TableDynamic
                title={this.props.match.params.route}
                configuration={elements.details}
                headers={(elements.details || []).slice(0, 4)}
                defaultFields={defaultFields}
                data={elements.result ? elements.result : []}
                hasCheckbox={options.hasCheckbox}
                hasEdit={options.hasEdit}
                hasDelete={options.hasDelete}
                filters={filters}
                // onFilterChange={(filter) => this.onFilterChange(filter)}
                onCheckboxClick={(elementId) =>
                  this.handleCheckBoxClick(elementId)
                }
                onCheckBoxCheckAll={(action, elementId) =>
                  this.handleCheckBoxSelectAll(action, elementId)
                }
                onUpdate={this.redirectToUpdate}
                onDelete={this.deleteProduct}
              />
            )}
            <Pagination
              currentPage={filters.page}
              registerCount={elements.row_count}
              limit={elements.page_size}
              onPaginationButtonClick={(page) =>
                this.handlePaginationClick(page)
              }
            />
          </>
        </div>
      </div>
    );
  }
}
