const Configuration = {
  getEntity: () => ({
    layout_configuration: {
      hide_event_period: true,
      hide_products_restriction: true,
    },
    configuration: [
      {
        identifier: 'devices',
        field: 'Dispositivos',
        type: 'reference-entity',
        options: {
          multiple: true,
        },
        reference_entity_properties: {
          options_fields: [
            {
              identifier: 'name',
              type: 'input-text',
            },
          ],
        },
      },
    ],
  }),
};

export default Configuration;
