import SDK from 'api.digitalpages.module.sdk.api';

const productsService = function () {
  this.getProductsAvailables = async function () {
    return await SDK.Api.store.getProductsAvailable();
  };
};

const ProductsService = new productsService();

export { ProductsService };
