import React, { Component } from 'react';
import './group-iot-management.scss';
import Sdk from 'api.digitalpages.module.sdk.api';
import {
  Header,
  Sidebar,
  Breadcrumb,
  FormDynamic,
  NotifyBox,
  ModalFormDynamic,
} from '../../components';
import Loading from '../../components/loading';
import Configuration from './group-iot-management.config';

export default class ViewGroupIotManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      selectOptions: [],
      ready: false,
      schemas: [],
      loading: true,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: null,
      modalEntity: {
        open: false,
      },
    };
  }

  componentDidMount() {
    this.getSchemaProperties().then(() => {
      const { uid } = this.props.match.params;
      if (uid !== 'new') {
        this.getEntityDetails().then(() => this.populateSelectOptions(true));
      } else {
        this.populateSelectOptions(true);
      }
    });
  }

  getSchemaProperties = async () => {
    const schema = this.props.match.params.schemaUid;
    const result = await Sdk.Api.authorization.entity.schema(schema);
    const entity = Configuration.getEntity();

    const translate = {
      name: 'Nome',
      description: 'Descrição',
      nome: 'Nome',
      descricao: 'Descrição',
    };

    const extendedFormProps = result.properties.map((prop, index) => ({
      field: translate[prop.name],
      type: 'input-text',
      identifier: prop.name,
      required: index == 0,
    }));

    this.setState({
      entity: {
        ...entity,
        configuration: [...extendedFormProps, ...entity.configuration],
      },
    });
  };

  populateSelectOptions = async (initialize) => {
    const devices = await Sdk.Api.iot.listDevices();
    const normalized = devices.result.map((device) => ({
      uid: device.uid,
      data: { name: device.name },
    }));

    this.setState({
      loading: false,
      selectOptions: [
        {
          identifier: 'devices',
          data: normalized,
        },
      ],
    });
  };

  getEntityDetails = async () => {
    const { uid } = this.props.match.params;

    const entity = await Sdk.Api.authorization.entity.getSchemaItem(uid);

    const devices = await Sdk.Api.iot.listDevices({
      entityUid: entity.uid,
      noCache: true,
    });

    this.setState({
      ready: true,
      values: {
        ...entity.data,
        devices: devices.result.map((device) => device.uid),
      },
    });
  };

  updateDevices = async (devices, entityUid) => {
    const update = devices.map(async (uid) => {
      const data = await Sdk.Api.iot.getDevice(uid);

      await Sdk.Api.iot.updateDevice(uid, {
        ...data.device,
        entities: [...data.device.entities, entityUid],
      });
    });

    await Promise.all(update);
  };

  createEntity = async (form) => {
    const result = await Sdk.Api.authorization.entity.createSchemaItem(
      this.props.match.params.schemaUid,
      {
        row: 0,
        column: 0,
        data: {
          ...form.data,
          devices: undefined,
        },
      }
    );

    await this.updateDevices(form.data.devices, result.uid);

    this.setState({
      loading: false,
      msgSuccess: 'Salvo com sucesso!',
      values: {},
    });
  };

  updateEntity = async (form) => {
    const result = await Sdk.Api.authorization.entity.updateSchemaItem(
      this.props.match.params.uid,
      {
        row: 0,
        column: 0,
        data: {
          ...form.data,
          devices: undefined,
        },
      }
    );

    await this.updateDevices(form.data.devices, result.uid);

    this.setState({
      loading: false,
      msgSuccess: 'Salvo com sucesso!',
    });
  };

  handleSubmit = async (form) => {
    this.setState({
      msgError: null,
      loading: true,
    });

    try {
      if (this.props.match.params.uid === 'new') {
        await this.createEntity(form);
      } else {
        await this.updateEntity(form);
      }
    } catch {
      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgError: 'Ocorreu um erro, tente novamente.',
      });
    }
  };

  resetMsg = () => {
    this.setState({ msgSuccess: null, msgError: null });
  };

  render() {
    const { consumer } = this.props;
    const {
      values,
      entity,
      loading,
      loadingSubmit,
      msgSuccess,
      msgError,
    } = this.state;

    return (
      <div className="rdp-admin-new-entity">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loading && <Loading msg="Carregando formulário..." />}
          {loadingSubmit && <Loading msg="Enviando..." />}
          {!loadingSubmit && msgSuccess && (
            <NotifyBox
              type="success"
              onClose={this.resetMsg}
              message={msgSuccess}
            />
          )}
          {!loadingSubmit && msgError && (
            <NotifyBox
              type="error"
              onClose={this.resetMsg}
              message={msgError}
            />
          )}
          {entity && (
            <FormDynamic
              actions={{}}
              selectOptions={this.state.selectOptions}
              values={values}
              isNew={true}
              cleanValues={msgSuccess ? true : false}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              entity={entity}
              hideDraftButton={true}
              submitText={'Salvar'}
              submitEnabled={true}
            />
          )}
        </div>
      </div>
    );
  }
}
