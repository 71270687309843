import React, { useEffect, useState } from 'react';
import './new-container.scss';
import { Header, Sidebar, Breadcrumb, Footer } from '../../components';
import DynamicForm from '../../components/form-dynamic-v2';

export default function NewContainer({ consumer, history }) {
  const [entity, setEntity] = useState();

  const getForm = async () => {
    const res = await fetch('./preferences.json');
    const { forms } = await res.json();
    setEntity(forms['new-container']);
  };

  useEffect(() => {
    getForm();
  }, []);

  const handleSubmit = () => {
    console.log('handleSubmit clicked!');
  };

  return (
    <div>
      <Header />
      <Sidebar
        currentRoute="/users/new"
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
      <div className="main-content">
        {
          entity ? 
            <DynamicForm
              handleSubmit={handleSubmit}
              form={entity}
              submitText="REGISTER"
            />:
            <div>Carregando...</div>
        }
      </div>
      <Footer version="01" />
    </div>
  );
}
