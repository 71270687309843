export function getConnectorFromArray(connectors, connectorType, connectorService) {
  var connector = null;

  for (var i = 0; i < connectors.length; i++) {
    if (connectors[i].service === connectorService && connectors[i].type === connectorType) {
      connector = connectors[i];
    };
  };

  return connector;
}
