import SDK from 'api.digitalpages.module.sdk.api';

const entityService = function () {
  this.getEntity = async function (entityId) {
    return await SDK.Api.news.getEntity(entityId);
  };

  this.getOptionsReference = async function (entityId, label, filter = {}) {
    const { result } = await SDK.Api.news.getOptionsReference(
      entityId,
      label,
      { all: true },
      filter,
    );
    return result;
  };
};

const EntityService = new entityService();

export { EntityService };
