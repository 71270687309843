import React from 'react'
import './component-tree.scss'
import {
  IconEdit
} from '../../components/icon';
const AddIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    \<path
      d="M9.5 9.5V5H11.5V9.5H16V11.5H11.5V16H9.5V11.5H5V9.5H9.5Z"
      fill="#9E9FA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5ZM19 10.5C19 15.1944 15.1944 19 10.5 19C5.80558 19 2 15.1944 2 10.5C2 5.80558 5.80558 2 10.5 2C15.1944 2 19 5.80558 19 10.5Z"
      fill="#9E9FA7"
    />
  </svg>
)
export default function ComponentTree({items, handleAddEntity}) {

  const getFirstItemFromObj = (obj) => {
    const firstKey = Object.keys(obj)[0]
    if (!firstKey) return
    return obj[firstKey]
  }
  return (
    <div style={{padding: '2rem'}}>
      {items.map(item => {
        return (
          <div
            key={item.uid}
            className="entity-card"
            style={{
              marginLeft: `${item.column * 50}px`,
              background: 'white',
              width: 500,
              padding: 10,
              borderRadius: '4px',
              marginBottom: '.5rem',
              border: '1px solid #ddd',
              
              WebkitBoxShadow: '5px 5px 15px -10px #000000',
              boxShadow: '5px 5px 15px -10px #000000'
            }}
          >
            <div>{getFirstItemFromObj(item.data)}</div>
            <button 
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 4,
                borderRadius: '4px'
              }}
              className="edit-button"
              onClick={() => handleAddEntity(item)}
            >
              <IconEdit />
            </button>
          </div>
        )
      })}
    </div>
  )
}
