import React, { Component } from 'react';
import './hierarchy-dynamic.scss';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default class HierarchyDynamic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: []
    };   
  }

  orderDraggableIndex = () => {
    const values = (this.state.values||[]).map((value, key) => ({
      ...value,
      _key: key
    }));

    this.setState({
      values
    })
  }

  componentDidMount() {
    if (!this.props.configuration) {
      throw new Error("É necessário definir a configuração do componente")
    }

    this.setState({
      values: this.props.values || []
    }, () => {
      this.orderDraggableIndex();
      this.addChild();
    });
  }

  getDefaultValue = () => {
    const { configuration } = this.props;
    const value = configuration.reduce(function(map, { identifier }) {
      if (identifier) map[identifier] = "";
      return map;
    }, {});

    value._empty = true;
    value._uid = uuidv4();

    return value;
  }

  addChild = parent => {
    const defaultValue = this.getDefaultValue();

    if (parent) {
      defaultValue._parent = parent._uid;
    }

    this.setState({
      values: [...this.state.values, defaultValue]
    }, () => {
      this.orderDraggableIndex();
    })
  }

  getChildren = parent => {
    return this.state.values.filter(({ _parent }) => parent._uid && parent._uid === _parent);
  }

  isCyrcle = (parent, child) => {
    const children = this.getChildren({ _uid: parent });
    
    let is = false;

    if (children) {
      children.forEach(c => {
        if (is) {
          return;
        }
        is = c._uid == child || this.isCyrcle(c._uid, child);
      })
    }

    return is;
  }

  hasChildren = parent => {
    return this.state.values.some(({ _parent, _removed }) => !_removed && parent._uid && parent._uid === _parent);
  }

  renderInputChildren = current => {
    return this.getChildren(current).map(this.renderInput);
  }

  getParent = ({ _parent }) => {
    return this.state.values.find(({ _uid }) => _uid == _parent)
  }
  
  renderInput = current => {
    const { configuration } = this.props;
    const defaultInput = configuration.find(({ identifier }) => ["name", "title", "nome", "título"].includes(identifier.toLowerCase()));
    const hasChildren = this.hasChildren(current);
    const parent = this.getParent(current);

    if (current._removed) {
      return null;
    }

    return (
      <Draggable id={current._key} key={current._key} index={current._key} draggableId={current._uid}>
        {(_provided, snapshot) => (
          <div
            ref={_provided.innerRef}
            {..._provided.draggableProps} 
          >
            <div data-testid="hierarchy-field" className="hierarchy-dynamic--field">
              <Droppable type="children" droppableId={current._uid}>
                {(provided, snapshot) => (
                  <>
                  <div  ref={provided.innerRef} {...provided.droppableProps}  className="hierarchy-dynamic--field-input">
                    {/* <a {..._provided.dragHandleProps} className="hierarchy-dynamic--field-move"></a> */}
                    <div className={`hierarchy-dynamic--field-input-wrapper ${hasChildren ? '_hierarchy-dynamic--margin-right' : ''}`}>
                      {defaultInput && <input onChange={e => this.changeValue(e, current, defaultInput.identifier)} type="text" value={current[defaultInput.identifier]} placeholder={defaultInput.label}/>}
                      <a onClick={() => {
                        this.props.onDetails(current);
                      }} className={`hierarchy-dynamic--field-btn-config ${current._empty ? 'empty' : ''}`}>Configuração</a>
                      <a onClick={() => this.remove(current)} className="hierarchy-dynamic--field-remove"></a>
                    </div>
                    <a className="hierarchy-dynamic--field-plus" onClick={() => this.addChild(parent)}></a>
                  </div>
              
                  <div>
                    {!hasChildren &&
                      <div className="hierarchy-dynamic--field-plus--wrapper">
                        <a className="hierarchy-dynamic--field-move"></a>
                        <a className="hierarchy-dynamic--field-plus--small" onClick={() => this.addChild(current)}></a>
                      </div>
                    }
                    {provided.placeholder}
                    {this.renderInputChildren(current)}
                  </div>
                  </>
                )}
              </Droppable>
            </div>
          </div>
        )}
      </Draggable>
    )
  }
  
  updateValue = (value, uid, arr, field) => {
    const key = this.state.values.findIndex(({ _uid }) => _uid === uid);
    if (!arr[key]) {
      arr[key] = {}
    }
    arr[key][field] = value;
    arr[key]._empty = false;
    return arr;
  }

  remove = current => {
    this.setState({
      values: this.updateValue(true, current._uid, this.state.values, '_removed')
    }, () => {
      this.orderDraggableIndex();
      this.props.onChange(this.props.id, this.state.values)
    })
  }

  move = (source, destination) => {
    destination = destination == "main" ? null : destination;

    this.setState({
      values: this.updateValue(destination, source, this.state.values, '_parent')
    }, () => {
      this.orderDraggableIndex();
      this.props.onChange(this.props.id, this.state.values);
    })
  }
  
  changeValue = ({ target }, current, field) => {
    const { value } = target;

    this.setState({
      values: this.updateValue(value, current._uid, this.state.values, field)
    }, () => {
      this.orderDraggableIndex();
      this.props.onChange(this.props.id, this.state.values)
    })
  }

  changeModalValue = (target, current) => {
    this.setState({
      values: this.updateValue(target.data, current._uid, this.state.values, target.id)
    }, () => {
      this.orderDraggableIndex();
      this.props.onChange(this.props.id, this.state.values)
    })
  }

  render() {
    return (
      <DragDropContext onDragEnd={(target) => {
        const { destination, source, draggableId } = target;
        if (destination && destination.droppableId) {
          if (destination.droppableId == draggableId) return;
          if (this.isCyrcle(draggableId, destination.droppableId)) return;

          this.move(draggableId, destination.droppableId);
        }
      }}>
        <div className="rdp-admin-component-table-dynamic">
          <Droppable type="children" droppableId={"main"}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef} 
                {...provided.droppableProps} 
                className="rdp-admin-component-table-dynamic--item"
              >
                {provided.placeholder}
                {this.state.values.filter(value => !value._parent).map(this.renderInput)}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    );
  }
}   
