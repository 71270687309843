import * as XLSX from 'xlsx';
import crypto from 'crypto';
import moment from 'moment';

let XLSX_HEADERS = [
  'Nome Completo',
  'Email',
  'Login',
  'Data de criação',
  'Bloqueado',
];

const generateHash = () => {
  return crypto.randomBytes(6).toString('hex');
};

const generateTimestamp = () => {
  return moment().format('DDMMYYYY_HHmmss');
};

const userName = (user) => {
  return user.detail.first_name
    ? user.detail.first_name + ' ' + user.detail.last_name
    : user.detail.name;
};

const getAdditionalProperties = (user) => {
  const { user_properties } = user;

  const structure = {
    headers: [],
    values: [],
  };

  (user_properties || []).forEach((property) => {
    structure.headers.push(property.name);
    structure.values.push(property.value || '-');
  });

  return structure;
};

function convertToXLSX(users) {
  let maped = false;

  users.sort((a, b) => new Date(b.detail.created_at) - new Date(a.detail.created_at));

  const rows = users.map((user) => {
    const { headers, values } = getAdditionalProperties(user);

    if (!maped) {
      XLSX_HEADERS = [...XLSX_HEADERS, ...headers];
      maped = true;
    }

    const formattedDate = formatDate(new Date(user.detail.created_at));

    return [
      userName(user),
      user.detail.email_principal || 'Sem email cadastrado',
      user.login,
      formattedDate,
      user.blocked_access ? 'Sim' : 'Não',
      ...values,
    ];
  });

  const xlsxContent = [XLSX_HEADERS, ...rows];

  return xlsxContent;
}

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function createYearlySheet(users) {
  const groupedByYear = users.reduce((acc, user) => {
    const year = new Date(user.detail.created_at).getFullYear();

    acc[year] = acc[year] || [];
    acc[year].push(user);

    return acc;
  }, {});

  const yearlyRows = Object.entries(groupedByYear).map(([year, userGroup]) => {
    return [year, userGroup.length];
  });

  return [['Ano', 'Total de Usuários'], ...yearlyRows];
}

function createMonthlySheet(users) {
  const groupedByMonth = users.reduce((acc, user) => {
    const month = moment(user.detail.created_at).format('YYYY-MM');

    acc[month] = acc[month] || [];
    acc[month].push(user);

    return acc;
  }, {});

  const monthlyRows = Object.entries(groupedByMonth).map(
    ([month, userGroup]) => {
      return [month, userGroup.length];
    }
  );

  return [['Mês', 'Total de Usuários'], ...monthlyRows];
}

export function downloadUsersAsXlsx(users, includeYearlySheet = true, includeMonthlySheet = true) {
  const xlsxContent = convertToXLSX(users);
  const wb = XLSX.utils.book_new();
  const wsUsers = XLSX.utils.aoa_to_sheet(xlsxContent);

  setWorksheetStyles(wsUsers);

  XLSX.utils.book_append_sheet(wb, wsUsers, 'Usuários');

  if (includeYearlySheet) {
    const yearlyContent = createYearlySheet(users);
    const wsYearly = XLSX.utils.aoa_to_sheet(yearlyContent);

    setWorksheetStyles(wsYearly);

    XLSX.utils.book_append_sheet(wb, wsYearly, 'Usuários por Ano');
  }

  if (includeMonthlySheet) {
    const monthlyContent = createMonthlySheet(users);
    const wsMonthly = XLSX.utils.aoa_to_sheet(monthlyContent);

    setWorksheetStyles(wsMonthly);

    XLSX.utils.book_append_sheet(wb, wsMonthly, 'Usuários por Mês');
  }

  const timestamp = generateTimestamp();
  const hash = generateHash();
  const fileName = `usuarios_${timestamp}_${hash}.xlsx`;

  XLSX.writeFile(wb, fileName);
}

function setWorksheetStyles(ws) {
  ws['!cols'] = [
    { wpx: 250 },
    { wpx: 250 },
    { wpx: 100 },
    { wpx: 120 },
    { wpx: 80 },
  ];

  if (ws['A1']) {
    Object.keys(ws).forEach((cell) => {
      if (cell[0] === '!') return;
      ws[cell].s = ws[cell].s || {};
      ws[cell].s.font = ws[cell].s.font || { name: 'Arial', sz: 11 };
      ws[cell].s.alignment = ws[cell].s.alignment || { horizontal: 'left', vertical: 'center' };
    });

    ws['A1'].s = {
      font: { bold: true, name: 'Arial', sz: 14, color: { rgb: 'FFFFFF' } },
      alignment: { horizontal: 'center', vertical: 'center' },
      fill: { fgColor: { rgb: '4F81BD' } },
    };

    for (let i = 0; i < XLSX_HEADERS.length; i++) {
      const headerCell = ws[XLSX.utils.encode_cell({ r: 0, c: i })];

      if (headerCell) {
        headerCell.s = {
          font: { bold: true, name: 'Arial', sz: 12, color: { rgb: 'FFFFFF' } },
          alignment: { horizontal: 'center', vertical: 'center' },
          fill: { fgColor: { rgb: '4F81BD' } },
        };
      }
    }
  }
}
