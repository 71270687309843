const textsPT = {
  true : "Sim",
  false : "Não",
  add_node: 'Adicionar nó',
  add_entity: 'Adicionar subgrupo',
  name : "Nome",
  description : "Descrição",
  blocked_access: 'blocked_access',

  button_login_as: 'Personificar',
  button_new_user: 'Novo usuário',
  button_new_project: 'Novo Projeto',
  button_update_project: 'Alterar Projeto',
  button_new_tree: 'Novo curso',
  button_new_schema_directory: 'Novo esquema por diretório',
  button_new_schema: 'Criar grupo',
  button_new_register: 'Nova oferta',
  button_update_roles: 'Alterar permissões',
  button_update_password: 'Alterar senha',
  button_update_register_entities: 'Atualizar permissões',
  button_update_register_details: 'Atualizar informações',
  button_insert_register_details: 'Salvar informações',
  button_insert_transaction: 'Adicionar transação',
  button_insert_revision: 'Criar revisão',
  button_new_import_curse: 'Importar curso',
  button_new_product: 'Criar Produto',
  button_new_transaction: 'Criar compra fake',
  button_remove_members: 'Remover Membros',
  button_add_new_member: 'Adicionando novo membro',
  button_save_members: 'Adicionar Membros',
  button_new_certificate: 'Criar certificado',
  button_new_access_external: 'Novo acesso externa',
  button_project_new: 'Criar projeto',
  button_project_update: 'Atualizar projeto',
  button_insert_content_managed_autorization: 'Criar nova autorização',

  breadcrumb_import_users: 'Importar usuários(csv)',
  breadcrumb_create: 'Criar',
  breadcrumb_entity: 'Entidade',
  breadcrumb_users: 'Usuários',
  breadcrumb_back: 'Voltar',
  breadcrumb_edition: '(Edição)',

  course_uid: 'uid',
  course_title: 'Título do curso',
  course_description: 'Descrição',
  course_internal_name: 'Título interno',
  course_created_at: 'Criado em ',
  course_updated_at: 'Atualizado em',
  course_type: 'Tipo',
  course_type_formula_score: 'Tipo de pontuação da fórmula',
  course_categories_description: 'Categorização geral do curso',
  course_categories_title: 'Categoria',
  course_field_created_at: 'Criado em',
  course_field_internal_name: 'Nome',
  course_field_tags: 'course_field_tags',
  course_field_title: 'Título',
  course_field_uid: 'UID',
  course_field_updated_at: 'Atualizado em',
  course_field_thumb_url: 'Capa',
  course_field_type: 'Tipo',
  course_field_type_formula_score: 'Tipo de pontuação da fórmula',
  course_list_description: 'Tabela de cursos cadastrados',
  course_list_title: 'Lista de cursos',
  course_registers_description:
    'Tabela de registro, disponibilidade, visibilidade e interação de ofertas',
  course_registers_title: 'Ofertas',
  course_structure_description:
    'A listagem abaixo representa os conteúdos cadastrados na plataforma.',
  course_structure_title: 'Conteúdos',
  course_thumb: 'Capa',
  couse_detail_description:
    'Detalhes de identificação da unidade de aprendizagem',
  couse_detail_title: 'Informações do curso',

  user_detail_cpf: 'CPF',
  user_detail_email_principal: 'E-mail',
  user_detail_first_name: 'Nome',
  user_detail_last_name: 'Sobrenome',
  user_detail_name: 'Como deve ser chamado',
  user_password: 'Senha',

  header_actived_project: 'Projeto',
  header_name_user: 'Usuário',
  header_translation: 'Idioma',
  header_translation_pt: 'Português',
  header_translation_en: 'Inglês',

  information___internal: 'information___internal',
  information_blocked_access:
    'Quando bloquedo, a plataforma não autoriza o login do usuário',
  information_connectors_data: 'information_connectors_data',
  information_created_at: ' ',
  information_updated_at: ' ',
  information_description: ' ',
  information_detail: 'information_detail',
  information_detail_cpf: ' ',
  information_detail_email_principal: ' ',
  information_detail_first_name: ' ',
  information_detail_last_name: ' ',
  information_internal_name: ' ',
  information_login: ' ',
  information_user_password: ' ',
  information_pending_confirm: 'information_pending_confirm',
  information_tags: 'information_tags',
  information_title: ' ',
  information_uid: ' ',
  information_user_blocked_access: 'information_user_blocked_access',
  information_user_connectors_data: 'information_user_connectors_data',
  information_user_created_at: 'Data de cadastro do usuário na plataforma',
  information_user_detail: 'information_user_detail',
  information_user_detail_cpf: ' ',
  information_user_detail_email_principal:
    'E-mail utilizado para comunicação com o usuário',
  information_user_detail_first_name: ' ',
  information_user_detail_last_name: ' ',
  information_user_login: ' ',
  information_user_pending_confirm:
    'Quando pendente, a plataforma pode não autorizar o login do usuário',
  information_user_uid: 'ID único do usuário na plataforma',
  information_user_updated_at: 'Última alteração registrada no usuário',
  information_user_detail_name: 'Nome utilizado para comunicação com o usuário',

  information_offer_name: 'Nome de identificação da oferta',
  information_offer_register_start:
    'A partir de quando a oferta estará disponível',
  information_offer_register_end: 'Até quando a oferta ficará disponível',
  information_offer_interaction_start:
    'A partir de quando as interações devem ser registradas',
  information_offer_interaction_end:
    'Até quando as interações de usuários serão registradas',
  information_offer_visibility_start:
    'A partir de quando a oferta estará visível aos usuários',
  information_offer_visibility_end:
    'Até quando a oferta ficará visível aos usuários',
  information_node_uid: 'Código de identificação da unidade',
  information_node_created_at: 'Data de criação da unidade',
  information_node_updated_at: 'Data da última atualização da unidade',
  information_node_data_title: 'Título da unidade de aprendizagem',
  information_node_duration: 'Duração da unidade',
  information_entity_uid: ' ',
  information_entity_row: ' ',
  information_entity_column: ' ',
  information_entity_data_nome: ' ',
  information_entity_roles: ' ',
  information_entity_created_at: ' ',
  information_entity_updated_at: ' ',
  information_course_uid: ' ',
  information_course_title: ' ',
  information_course_internal_name: ' ',
  information_course_created_at: ' ',
  information_course_updated_at: ' ',
  information_course_type: ' ',
  information_course_type_formula_score: ' ',

  entity_structure_title: 'Hierarquia do grupo',
  entity_structure_description: 'Estrutura das unidades de entidade',

  member_structure_title: 'Membros',
  member_structure_description: 'Adicionar novos membros',

  loading_message: 'Carregando...',

  login: 'Usuário',
  login_message_welcome: 'Entre no RDP Manager com suas credenciais.',
  login_email: 'Email ou usuário',
  login_password: 'Senha',
  login_button: 'Entrar',
  login_message_error_credentials:
    'Credenciais inválidas, verifque e tente novamente.',
  login_message_error_fields_credentials:
    'Preencha os campos com suas credenciais.',

  menu_group_apps: 'Aplicações',
  menu_group_configs: 'Configurações',
  menu_group_iot: 'IoT',
  menu_group_learning: 'Aprendizagem',
  menu_group_review: 'Revisões',
  menu_group_store: 'Loja',
  menu_group_dashboards: 'Painéis',
  menu_option_apps_app: 'RDP App',
  menu_option_apps_composer: 'RDP Composer',
  menu_option_apps_guides: 'Guides',
  menu_option_apps_dam: 'RDP Dam',
  menu_option_configs_entities: 'Entidades',
  menu_option_configs_group: 'Grupos',
  menu_option_configs_directory_users: 'Usuários no diretório',
  menu_option_configs_project_users: 'Usuários no projeto',
  menu_option_learning_certificates: 'Certificados',
  menu_option_learning_courses: 'Cursos',
  menu_option_review_flows: 'Revisões',
  menu_option_review_rubrics: 'Rubricas',
  menu_option_store_products: 'Produtos',
  menu_option_store_transactions: 'Transações',
  menu_option_access_external: 'Acesso Externo',
  menu_option_managed_content: 'Conteúdos',
  menu_option_project_list: 'Projetos',

  pending_confirm: 'Confirmação pendente',
  blocked_access: 'Acesso bloqueado',
  blocked_acess: 'Acesso bloqueado',
  register_field_uid: 'uid (oferta)',
  register_user: 'Cadastrar',
  filter_user: 'Filtro',

  register_access_status_blocked: 'Bloqueado',
  register_access_status_pendingpayment: 'Pagamento pendente',
  register_access_status_paymentfailed: 'Falha no pagamento',
  register_access_status_active: 'Ativo',
  register_access_status_inactive: 'Inativo',

  register_register_status_notattempted: 'Não iniciado',
  register_register_status_started: 'Em andamento',
  register_register_status_passed: 'Passou',

  screen_certificate_title: 'Certificado',
  screen_course_title: 'Cursos',
  screen_user_title: 'Lista de usuários',
  screen_products_title: 'Produtos',
  screen_course_edit_title: 'Editar curso',
  screen_new_product_title: 'Criar produto',
  screen_transactions_title: 'Transações',
  screen_schema_new_entity: 'Definições do grupo',
  screen_managed_content: 'Conteúdos',


  tab_course_categories: 'Categorias',
  tab_course_content: 'Conteúdo',
  tab_course_information: 'Informações do curso',
  tab_course_offers: 'Ofertas',
  tab_user_groups: 'Permissões e grupos',
  tab_user_information: 'Informações',
  tab_user_offers: 'Ofertas disponíveis',
  tab_user_registers: 'Matrículas',
  tab_user_transactions: 'Transações',
  tab_group_schema: 'Esquema',
  tab_group_schema_scope_project: 'Projeto',
  tab_group_schema_scope_directory: 'Diretório',
  table_header_register_description: 'Descrição',
  tab_group_schema_category: 'Categoria',
  tab_group_schema_security: 'Segurança',
  tab_members_emtity_information: 'Hierarquia',
  tab_members_information: 'Membros',

  table_header_register_interaction_end: 'Fim da interação',
  table_header_register_interaction_start: 'Início da interação',
  table_header_register_name: 'table_header_register_name',
  table_header_register_register_end: 'Fim da oferta',
  table_header_register_register_start: 'Início da oferta',
  table_header_register_uid: 'table_header_register_uid',
  table_header_register_visibility_end: 'Fim da visualização',
  table_header_register_visibility_start: 'Início da visualização',
  table_header_register_course_type: 'Tipo',
  table_header_register_course_type_formula_score:
    'Tipo de pontuação da fórmula',
  table_header_role_type: 'Permissão',
  table_header_role_type_description: 'Atribuições',
  table_header_register_course_thumb_url: 'Capa',
  table_header_register_uid: 'uid (oferta)',
  table_header_register_name: 'nome (oferta)',
  table_header_register_course_uid: 'uid (curso)',
  table_header_register_course_title: 'nome (curso)',
  table_header_user_register_uid: 'uid (matrícula)',
  table_header_user_register_access_status: 'status (acesso)',
  table_header_user_register_register_status: 'status (matrícula)',
  table_header_user_register_score: 'pontuação',
  table_header_user_register_created_at: 'criado em',
  table_header_user_register_course_uid: 'uid (curso)',
  table_header_user_register_course_title: 'nome (curso)',
  table_header_user_register_register_uid: 'uid (oferta)',
  table_header_user_register_register_name: 'nome (oferta)',
  table_header_user_register_register_description: 'descrição (oferta)',

  table_header_schema_uid: 'uid',
  table_header_schema_name: 'nome',
  table_header_schema_public: 'público',
  table_header_schema_self_register: 'auto-cadastro',
  table_header_schema_created_at: 'Data de criação',
  table_header_schema_updated_at: 'Data de atualização',
  table_header_schema_type: 'tipo',
  table_header_schema_scope: 'Escopo',
  table_header_schema_limit_depth_in_hierarchy: 'visibilidade (limite)',

  table_header_product_uid: 'uid',
  table_header_product_name: 'Nome do produto',
  table_header_product_unique_name: 'Id único',
  table_header_product_currency: 'Moeda',
  table_header_product_created_at: 'Criado em',

  table_header_user_transaction_uid: 'uid',
  table_header_user_transaction_external_uid: 'uid externo',
  table_header_user_transaction_active: 'Ativado',
  table_header_user_transaction_status: 'Status',
  table_header_user_transaction_payment_type: 'Tipo de pagamento',
  table_header_user_transaction_created_at: 'Criado em',
  table_header_user_transaction_updated_at: 'Atualizado em',

  table_header_transaction_uid: 'uid',
  table_header_transaction_user_uid: 'UID do usuário',
  table_header_transaction_user_name: 'Nome do usuário',
  table_header_transaction_external_uid: 'uid externo',
  table_header_transaction_active: 'Ativado',
  table_header_transaction_status: 'Status',
  table_header_transaction_payment_type: 'Tipo de pagamento',
  table_header_transaction_created_at: 'Criado em',
  table_header_transaction_updated_at: 'Atualizado em',

  table_header_members_user_name: 'Nome',
  table_header_members_user_uid: 'uid do usuário',
  table_user_no_item: 'Nenhum item',

  transaction_status_paid: 'Pago',
  transaction_status_pendingreview: 'Aguardando revisão',
  transaction_status_waitingpayment: 'Aguardando pagamento',
  transaction_status_none: 'Indefinido',
  transaction_status_chargedback: 'Cobrado de volta',
  transaction_status_refused: 'Rejeitado',
  transaction_status_authorized: 'Autorizado',
  transaction_status_pendingrefund: 'Reembolso em andamento',
  transaction_status_refunded: 'Reembolsado',
  transaction_status_processing: 'Processando',
  transaction_status_analyzing: 'Em análise',
  transaction_status_renewed: 'Renovado',

  transaction_payment_card: 'Cartão de crédito',
  transaction_payment_pix: 'Pix',

  schema_public : "Público",
  schema_scope : "Escopo",
  schema_scope_directory: "Diretório",
  schema_scope_project: "Projeto",
  schema_detail_title: 'Grupos cadastrados',
  schema_detail_description:
    'Na listagem abaixo, constam todos os grupos cadastrados na plataforma. Um grupo pode ser do tipo "Categoria", para categorização de recursos (arquivos, cursos...), ou do tipo "Segurança", com objetivo de controlar o acesso a um determinado recurso (arquivo, oferta...) com base em seus membros. Para criar uma novo grupo, selecione a opção "Criar grupo". Parar criar uma hierarquia dentro do grupo ou atualizar os membros, seleciona o ícone de editar.',
  screen_schema_title: 'Grupos',
  screen_schema_new_directory: 'Cadastrar Esquema Diretório',
  screen_schema_new_project: 'Novo grupo',
  screen_schema_new_access_external: 'Novo acesso external',
  screen_schema_edit_access_external: 'Editar acesso external',
  screen_new_project: 'Novo Projeto',
  screen_edit_project: 'Editar Projeto',
  list_projects_title: 'Projetos',
  list_projects_description: 'Na listagem constam todas os Projetos na plataforma.',
  project_new_information_title: 'Novo Projeto',
  project_edit_information_title: 'Editar Projeto',
  project_new_information_description: 'No formulário abaixo adicione as informações do Projeto para criar um novo cadastro na plataforma.',
  project_edit_information_description: 'No formulário abaixo adicione novas informações do Projeto para realizar a alteração do cadastro na plataforma.',

  modal_user_register_title: 'Matricular usuário',
  modal_user_change_access_status_title: 'Status de acesso',
  modal_user_change_access_status_message: 'Alterar status de acesso para:',
  modal_user_change_access_status_button: 'Alterar',

  message_registration: 'Tem certeza de que deseja registrar usuário',
  modal_register_user_title: 'Matricular usuário',
  modal_register_user_button: 'Matricular',
  modal_register_user_message:
    'Tem certeza de que deseja matricular o usuário nesta oferta?',

  modal_edit_transaction_title: 'Editar transação',
  modal_edit_transaction_message: 'Alterar status da transação para:',
  modal_edit_transaction_button: 'Alterar',

  modal_title_add_member: 'Adicionar novo membro',
  placeholder_search_user: 'Pesquisar por uid, nome ou login',

  title_input_confirm_password: 'title_input_confirm_password',
  title_input_password: 'title_input_password',
  title_user_login: 'Personificar',
  title_user_transaction: 'Nova transação',
  update: 'Atualizar',

  user_blocked_access: 'Acesso bloqueado',

  button_update_password: 'Alterar senha',

  user_created_at: 'Data de criação',
  user_detail_cpf: 'CPF',
  user_detail_email_principal: 'E-mail principal',
  user_detail_first_name: 'Primeiro nome',
  user_detail_last_name: 'Sobrenome',
  user_edit_information_button_update_user: 'Atualizar informações',
  user_edit_information_description:
    'No formulário abaixo, atualize os dados do usuário cadastrado na plataforma. Além da possibilidade de bloquear o acesso ou cancelar a necessidade de confirmação do email cadastrado pelo usuário. No menu superior, acesse as aplicações como o usuário, pela opção "Personificar", ou altere manualmente a senha do usuário em "Alterar senha".',
  user_edit_information_title: 'Detalhes referente ao usuário',
  user_edit_offers_available_description:
    'Na listagem abaixo, todas as ofertas e seu respectivo curso que atualmente estão visíveis para o usuário se matricular. Para efetuar uma matrícula manual, escolha a oferta desejada e clique no ícone de editar. Qualquer alteração é refletida de imediato para o usuário.',
  user_edit_offers_available_title: 'Ofertas visíveis para o usuário',
  user_edit_register_linked_description:
    'Na listagem abaixo, todas as matrículas relacionadas ao usuário, ativas ou não na plataforma. Caso seja necessário alterar o status de uma matrícula, escolha a matrícula desejada e clique no ícone de editar. Qualquer alteração é refletida de imediato para o usuário.',
  user_edit_register_linked_title: 'Matrículas vinculadas',
  user_field_blocked_access: 'bloqueado',
  user_field_created_at: 'criado em',
  user_field_detail: 'user_field_detail',
  user_field_detail_name: 'nome',
  user_field_login: 'login',
  user_field_pending_confirm: 'pendente confirmação',
  user_field_uid: 'uid',
  user_field_updated_at: 'atualizado em',
  user_groups_edit_entities_description:
    'Na listagem abaixo, constam todos os grupos cadastrados do tipo "Segurança". Relacionando o usuário em um grupo, possibilita um controle centralizado da permissões do usuário na plataforma (controlado via grupo) e principalmente no controle de acesso. Grupos podem ser adicionados ou removidos em "Configurações => Grupos".',
  user_groups_edit_entities_title: 'Grupos participantes',
  user_groups_edit_roles_description:
    'A permissão padrão é de "Espectador", que garante o usuário visualizar apenas o que foi concedido. Cada permissão atrelada ao usuário, representa um recurso ou funcionalidade liberada na plataforma. Para adicionar ou remover novas permissões, clique no botão superior "Alterar permissões".',
  user_groups_edit_roles_title: 'Permissões atreladas ao usuário',
  user_login: 'login',
  user_pending_confirm: 'Aguardando confirmação de e-mail',
  user_title_screen: 'Editar usuário',
  user_title_updated_password: 'Alterar senha',
  user_uid: 'uid',
  user_updated_at: 'Data de atualização',
  user_new_password: 'Nova senha',
  user_edit_transactions: 'Histórico de transações',
  user_edit_description_transactions:
    'Na listagem abaixo, constam todas as compras efetuadas pelo usuário dentro da plataforma. Estando uma compra paga, a liberação dos recursos atrelados ao produto é feito de forma automática. Caso deseja liberar um produto sem a necessidade de pagamento, selecione a opção "Criar compra fake" no menu superior. Uma vez adicionada a compra, a alteração é refletida de imediato para o usuário.',
  user_new_password_confirm: 'Confirmar nova senha',
  title_user_roles: 'Permissões do usuário',

  table_user_offers_message_no_item: 'Sem items na tabela de ofertas',

  no_result: 'Resultado não encontrado.',
  modal_node_edit_title: 'Editar Nó',
  node_edit_title: 'Edição de Unidade de Aprendizagem',
  node_edit_description: 'Configurações gerais da unidade de aprendizagem',
  node_thumb: 'Imagem',
  button_update_thumb: 'Alterar imagem',

  node_config_base: 'Configuração',
  node_uid: 'uid',
  node_created_at: 'Criado em',
  node_updated_at: 'Atualizado em',
  node_data_title: 'Título',
  node_data_description: 'Descrição',
  node_duration: 'Duração',
  node_interaction_interval_start: 'Liberar acesso após:',
  node_interaction_interval_end: 'Bloquear acesso após:',
  node_row: 'Linha',
  node_column: 'Coluna',
  node_products: 'Produtos',
  button_update_config_base: 'Atualizar',
  node_override_info_title: 'Sobrepor oferta',
  node_override_info_description: 'Substituir oferta-base do nó por:',
  node_override_interaction_interval_start: 'Liberar acesso após:',
  node_override_interaction_interval_end: 'Bloquear acesso após:',
  node_override_row: 'Linha',
  node_override_column: 'Coluna',
  node_type: 'Tipo',
  node_type_tags: 'Tipo de tag',
  node_type_tags_placeholder: 'Digite o tipo',
  node_type_formula_score: 'Tipo de pontuação da fórmula',
  node_type_tags_label_lesson: 'Lição',
  node_type_tags_label_project: 'Projeto',
  node_type_tags_label_course: 'Curso',
  node_type_tags_label_module: 'Módulo',

  screen_revision_title: 'Revisões',

  activity_edit: 'Editar Atividade',
  activity_edit_title: 'Atividade de unidade de aprendizagem',
  activity_edit_description: 'Configurações gerais da atividade',
  activity_config_base: 'Configuração',

  modal_activity_edit_uid: 'UID',
  modal_activity_edit_name: 'Nome da atividade',
  modal_activity_edit_score_min: 'Nota mínima',
  modal_activity_edit_score_max: 'Nota máxima',
  modal_activity_edit_score_weight: 'Peso da nota',
  modal_activity_edit_score_group: 'Nota de grupo',
  modal_activity_edit_duration: 'Duração',
  modal_activity_edit_maximum_attempts_after_completed:
    'Máximo de tentativas após conclusão',
  modal_activity_edit_maximum_attempts_after_failed:
    'Máximo de tentativas após reprovação',
  modal_activity_edit_maximum_attempts_after_passed:
    'Máximo de tentativas após aprovação',
  modal_activity_edit_required_predecessor_completion:
    'Exige conclusão da atividade anterior?',
  modal_activity_edit_content_upload_required:
    'Exige o envio de conteúdo pelo usuário?',
  modal_button_confirm_delete_node: 'Sim',
  modal_definitions_group: 'Definições do grupo',

  activity_button_update: 'Atualizar',
  button_change_activity: 'Atualizar atividade',
  modal_activity_edit_created_at: 'Criado em ',
  modal_activity_edit_updated_at: 'Atualizado em',

  offer_insert: 'Nova oferta',
  offer_edit: 'Editar oferta',
  offer_edit_detail_title: 'Cadastrar oferta',
  offer_edit_detail_description:
    'Definir informações, disponibilidade, interação e visibilidade da oferta',
  offer_edit_entity_title: 'Título da permissão por entidades',
  offer_edit_entity_description: 'Descrição da permissão por entidades',
  offer_uid: 'uid',
  offer_name: 'Nome da oferta',
  offer_description: 'Descrição',
  offer_register_start: 'Início da disponibilidade',
  offer_register_end: 'Fim da disponibilidade',
  offer_visibility_start: 'Início da visibilidade',
  offer_visibility_end: 'Fim da visibilidade',
  offer_interaction_start: 'Início da interação',
  offer_interaction_end: 'Fim da interação',

  node_add_node: 'Adicionar nó',
  node_add_activity: 'Adicionar atividade',

  default_new_activity_name: 'Nova atividade',
  default_new_schema_name: 'Nova atividade',
  default_new_register_name: 'Nova oferta',
  default_message_empty_result: 'Nenhum resultado encontrado',
  default_days: 'dias',
  default_hours: 'horas',
  default_minutes: 'minutos',
  default_seconds: 'segundos',
  default_true: 'Sim',
  default_false: 'Não',

  success_message_register_update: 'Oferta atualizada com sucesso',
  pending_message_register_update: 'Atualizando oferta',
  error_message_register_update: 'Falha ao atualizar oferta',

  success_message_schema_create: 'Grupo criado com sucesso',
  pending_message_schema_create: 'Criando grupo',
  error_message_schema_create: 'Falha ao criar grupo',

  success_message_register_insert: 'Oferta criada com sucesso',
  pending_message_register_insert: 'Criando oferta',
  error_message_register_insert: 'Falha ao criar oferta',

  success_message_user_entity_insert: 'Usuário atribuído ao grupo com sucesso',
  pending_message_user_entity_insert: 'Atribuíndo usuário ao grupo',
  error_message_user_entity_insert: 'Falha ao atribuír usuário ao grupo',

  success_message_user_entity_delete: 'Usuário removido do grupo com sucesso',
  pending_message_user_entity_delete: 'Removendo usuário do grupo',
  error_message_user_entity_delete: 'Falha ao remover usuário do grupo',

  success_message_user_info_update: 'Atualizado informações do usuário',
  pending_message_user_info_update: 'Atualizando informações do usuário',
  error_message_user_info_update: 'Falha ao atualizar informações do usuário',

  success_message_information_course: 'Atualizado informações do curso',
  pending_message_information_course: 'Atualizando informações do curso',
  error_message_information_course: 'Falha ao atualizar informações do curso',

  success_message_categories_course: 'Atualizado informações da categoria',
  pending_message_categories_course: 'Atualizando informações da categoria',
  error_message_categories_course:
    'Falha ao atualizar informações da categoria',

  success_message_user_password_update: 'Senha do usuário atualizada',
  pending_message_user_password_update: 'Atualizando senha do usuário',
  error_message_user_password_update: 'Falha ao atualizar senha do usuário',
  error_message_user_password_confirmation: 'Erro validando senhas cadastradas',

  success_message_user_register_status_update:
    'Status da matrícula do usuário atualizado',
  pending_message_user_register_status_update:
    'Atualizando status da matrícula do usuário',
  error_message_user_register_status_update:
    'Falha ao atualizar status da matrícula do usuário',

  success_message_user_register_insert:
    'Usuário matriculado no curso com sucesso',
  pending_message_user_register_insert: 'Matriculando usuário no curso',
  error_message_user_register_insert: 'Falha ao matricular usuário no curso',

  success_message_user_add_role:
    'Permissão atribuída com sucesso para o usuário',
  pending_message_user_add_role: 'Atribuíndo permissão para o usuário',
  error_message_user_add_role: 'Falha ao atribuír permissão para o usuário',

  success_message_user_remove_role: 'Permissão removida com sucesso do usuário',
  pending_message_user_remove_role: 'Removendo permissão do usuário',
  error_message_user_remove_role: 'Falha ao remover permissão do usuário',

  success_message_schema_delete: 'Grupo removido',
  pending_message_schema_delete: 'Removendo grupo',
  error_message_schema_delete: 'Falha ao remover grupo',

  pending_message_new_product_insert: 'Cadastrando novo produto',
  success_message_new_product_insert: 'Produto cadastrado com sucesso',
  error_message_new_product_insert: 'Falha ao cadastrar produto',

  success_message_new_user: 'Usuário cadastrado com sucesso',
  pending_message_new_user: 'Cadastrando novo usuário',
  error_message_new_user: 'Falha ao cadastrar novo usuário',

  success_message_user_add_products:
    'Transação cadastrada com sucesso para o usuário',
  pending_message_user_add_products: 'Adicionando trasnsação para o usuário',
  error_message_user_add_products:
    'Falha ao cadastrar transação para o usuário',

  success_message_status_transaction: 'Transação alterada com sucesso',
  pending_message_status_transaction: 'Alterando status da transação',
  error_message_status_transaction: 'Erro ao alterar status da transação',

  pending_message_user_remove_member: 'Removendo membro de entidade',
  success_message_user_remove_member: 'Membro removido',
  error_message_user_remove_member: 'Falha ao remover membro',

  pending_message_user_add_member: 'Adicionando membro de entidade',
  success_message_user_add_member: 'Membro adicionado com sucesso',
  error_message_user_add_member: 'Erro ao adicionar membro',

  pending_message_certificate_delete: 'Apagando certificado',
  success_message_certificate_delete: 'Certificado apagado',
  error_message_certificate_delete: 'Falha ao remover certificado',

  pending_message_created_node: 'Criando nó',
  success_message_created_node: 'Nó criado com sucesso',
  error_message_created_node: 'Falha ao criar nó',

  success_message_updated_activity: 'Atualizado informações da atividade',
  error_message_updated_activity: 'Falha ao atualizar informações da atividade',

  success_message_deleted_activity: 'Atividade deletada',
  error_message_deleted_activity: 'Falha ao remover atividade',

  success_message_updated_node: 'Atualizado informações do nó',
  error_message_updated_node: 'Falha ao atualizar informações do nó',

  success_message_deteted_node: 'Nó deletado com sucesso',
  error_message_deteted_node: 'Falha ao deletar nó',

  success_message_create_authorization: 'Autorização criada',
  error_message_create_authorization: 'Falha ao criar uma autorização',
  pending_message_create_authorization: 'Criando autorização',

  success_message_updated_authorization: 'Autorização atualizada',
  error_message_updated_authorization: 'Falha ao atualizar uma autorização',
  pending_message_updated_authorization: 'Atualizando autorização',

  success_message_updated_node: 'Nó atualizado',
  error_message_updated_node: 'Falha ao atualizar nó',
  pending_message_updated_node: 'Atualizando nó',

  success_message_access_external_create: 'Acesso externo criado com sucesso',
  pending_message_access_external_create: 'Criando acesso externo',
  error_message_access_external_create: 'Erro ao criar acesso externo',

  pending_message_access_external_delete: 'Removendo Acesso externo',
  success_message_access_external_delete: 'Acesso externo removido',
  error_message_access_external_delete: 'Falha ao remover acesso externo',

  success_message_created_activity: 'Atividade criada com sucesso',
  error_message_created_activity: 'falha ao criar ativiadade',

  pending_message_create_new_content: 'Criando conteúdo',
  success_message_create_new_content: 'Conteúdo criado',
  error_message_create_new_content: 'Falha ao criar o conteúdo',

  pending_message_update_new_content: 'Atualizando conteúdo',
  success_message_update_new_content: 'Conteúdo atualizada',
  error_message_update_new_content: 'Falha ao atualizar a conteúdo',

  pending_message_update_new_category: 'Atualizando categoria',
  success_message_update_new_category: 'Categoria atualizada',
  error_message_update_new_category: 'Falha ao atualizar a Categoria',

  pending_message_create_new_activity: 'Criando atividade',
  success_message_create_new_activity: 'Atividade criada',
  error_message_create_new_activity: 'Falha ao criar o atividade',

  pending_message_update_new_activity: 'Atualizando atividade',
  success_message_update_new_activity: 'Atividade atualizada',
  error_message_update_new_activity: 'Falha ao atualizar a atividade',

  pending_message_access_delete_activity: 'Removendo atividade',
  success_message_access_delete_activity: 'Atividade removida',
  error_message_access_delete_activity: 'Falha ao remover atividade',

  pending_message_create_new_project: 'Criando projeto',
  success_message_create_new_project: 'Projeto criado',
  error_message_create_new_project: 'Falha ao criar projeto',

  select_edit_node_register_unselected: 'Nenhuma oferta selecionada',

  new_product_name: 'Nome',
  new_product_currency: 'Moeda',
  new_product_amount: 'Valor',
  new_product_installments_max: 'Parcelas',
  new_product_unique_name: 'Identificação única do produto',
  new_product_installments: 'Parcelas',
  new_product_installments_placeholder: 'Digite a parcela',

  information_new_product_name: 'Insira o nome do produto',
  information_new_product_currency:
    'Insira o símbolo da moeda pela qual o produto será negociado. Exemplos: R$ para real, US$ para dólar americano',
  information_new_product_amount:
    'Insira o preço pelo qual o produto será negociado',
  information_new_product_unique_name:
    'Preencher a identificação única do produto com caracteres alfanuméricos e/ou especiais, sem espaços',
  information_new_product_installments_max:
    'Insira o número máximo de parcelas permitido',

  success_message_course_delete: 'Curso removido com sucesso',
  pending_message_course_delete: 'Removendo curso',
  error_message_course_delete: 'Falha ao remover curso',

  success_message_course_clone: 'Curso clonado com sucesso',
  pending_message_course_clone: 'Clonando curso',
  error_message_course_clone: 'Falha ao clonar curso',

  select_edit_node_register_unselected: 'Nenhuma oferta selecionada',

  role_type_inherited: 'Herdado',
  role_type_anonymous: 'Anônimo',
  role_type_viewer: 'Espectador',
  role_type_platformsuper: 'Superusuário',
  role_type_platformadministrator: 'Administrador da plataforma',
  role_type_administratorglobal: 'Administrador global',
  role_type_contributorglobal: 'Contribuidor global',
  role_type_readerglobal: 'Leitor global',
  role_type_system: 'Sistema',
  role_type_guideadmin: 'Administrador de guia',
  role_type_guidecontributor: 'Contribuidor de guia',
  role_type_templateadmin: 'Administrador de Modelo',
  role_type_templatecontributor: 'Contribuidor de modelo',
  role_type_templateviewer: 'Visualizador de modelos',
  role_type_learningadmin: 'Administrador de aprendizagem',
  role_type_learningcontributor: 'Contribuidor de aprendizagem',
  role_type_reviewadmin: 'Administrador de revisão',
  role_type_reviewcontributor: 'Contribuidor da revisão',
  role_type_useradmin: 'Administrador de usuários',
  role_type_schemaadmin: 'Administrador de esquema',
  role_type_schemacontributor: 'Contribuidor de esquema',
  role_type_entityadmin: 'Administrador de entidades',
  role_type_entitycontributor: 'Contribuidor de entidades',
  role_type_learninguseradmin: 'Administrador de usuários de aprendizagem',
  role_type_socialmediaadmin: 'Administrador de mídias sociais',
  role_type_socialmediacontributor: 'Contribuidor de mídias sociais',
  role_type_userimpersonate: 'Personificação de usuário',
  role_type_notificationadmin: 'Administrador de notificações',
  role_type_managedcontentadmin: 'Administrador de conteúdo gerenciado',
  role_type_managedcontentcontributor: 'Contribuidor de conteúdo gerenciado',

  role_description_type_inherited: '',
  role_description_type_anonymous: '',
  role_description_type_viewer: 'Acesso limitado aos recursos da plataforma. Permissão mais baixa de um usuário autenticado.',
  role_description_type_platformsuper: 'Acesso a todos os recursos e projetos cadastrados na plataforma.',
  role_description_type_platformadministrator: 'Acesso a todos os recursos e projetos cadastrados na plataforma.',
  role_description_type_administratorglobal: 'Acesso a todos os recursos e projetos cadastrados na diretório.',
  role_description_type_contributorglobal: 'Acesso a todos os recursos do projeto vinculado.',
  role_description_type_readerglobal: '',
  role_description_type_system: '',
  role_description_type_guideadmin: '',
  role_description_type_guidecontributor: '',
  role_description_type_templateadmin: '',
  role_description_type_templatecontributor: '',
  role_description_type_templateviewer: '',
  role_description_type_learningadmin: '',
  role_description_type_learningcontributor: '',
  role_description_type_reviewadmin: '',
  role_description_type_reviewcontributor: '',
  role_description_type_useradmin: 'Permissão para adicionar e editar usuários.',
  role_description_type_schemaadmin: 'Permissão para criar e editar entidades e definições.',
  role_description_type_schemacontributor: 'Permissão para editar entidades e suas definições.',
  role_description_type_entityadmin: 'Permissão para adicionar e editar as entidades.',
  role_description_type_entitycontributor: 'Permissão para editar as entidades cadastradas',
  role_description_type_learninguseradmin: '',
  role_description_type_socialmediaadmin: '',
  role_description_type_socialmediacontributor: '',
  role_description_type_userimpersonate: '',
  role_description_type_notificationadmin: '',
  role_description_type_managedcontentadmin: 'Permissão total envolvendo os conteúdos de aprendizagem.',
  role_description_type_managedcontentcontributor: 'Permissão para editar os metadatas dos conteúdos, atualizar apontamentos, adicionar e remover produtos e adicionar e remover autorizações.',

  rubric_create: 'Criar Rubrica',
  rubric_uid: 'UID',
  rubric_title: 'Título',
  rubric_title_placeholder: 'Digite Título da Rubrica',
  rubric_description: 'Descrição',
  rubric_criterion: 'Critérios',
  rubric_value: 'Valor',
  rubric_value_description: 'Valor da Descrição',
  rubric_description_placeholder: 'Digite a Descrição da Rubrica',
  rubric_created_at: 'Data de criação',
  rubric_updated_at: 'Data de atulização',
  rubric_delete: 'Excluir',

  schema_name: 'Nome',
  schema_type: 'Tipo de grupo',
  schema_limit_depth_in_hierarchy:
    'Limitar visibilidade da entidade sobre seus filhos',
  schema_properties: 'Propriedades exigidas das entidades',
  schema_tags: 'Tags',
  schema_option_category: 'Categoria',
  schema_option_security: 'Segurança',

  entity_uid: 'Uid',
  entity_name: 'Nome',
  entity_row: 'Linha',
  entity_column: 'Coluna',
  entity_data_nome: 'Nome',
  entity_roles: 'Roles',
  entity_created_at: 'Data de criação',
  entity_updated_at: 'Data de atualização',

  certificate_type: 'Template de certificado',
  certificates_detail_title: 'Certificados cadastrados',
  certificates_detail_description:
    'Abaixo, todos os modelos de certificados cadastrados na plataforma. Uma vez cadastrado o certificado, ele estará disponível para uso dentro das ofertas do curso. Para criar um novo certificado, selecione a opção "Criar certificado".',
  table_header_certificate_title: 'Certificado',
  table_header_certificate_document_created_at: 'Data de criação',
  table_header_certificate_document_last_modified: 'Data de atualização',

  schema_new_title: 'Definições do novo grupo',
  schema_new_description:
    'Defina um nome e o tipo desejado para o novo agrupamento. A hierarquia relacionada ao grupo, será cadastrada na próxima etapa, agora, apenas especifique os atributos desejados para cada subgrupo. Exemplo: Em um grupo com objetivo de controlar o usuários com base em diferentes unidades de uma escola, onde cada unidade teria um nome e um CPNJ para controle em relatórios, teriámos a seguinte configuração:\nUm grupo do tipo "Segurança", com duas propriedades do tipo texto, "Nome" e "CNPJ".',

  access_external_ip_range: 'range ip',
  access_external_type: 'Tipo',
  access_external_project: 'Projeto',
  access_external_entity: 'Entidade',
  access_external_new_create: 'Criar novo acesso externo',
  access_external_uid: 'uid',
  access_external_ip_range_filter: 'range ip',
  access_external_created_at: 'Data de criação',
  access_external_updated_at: 'Data de atualização',
  access_external_title: 'Definição do novo acesso externo',
  access_external_description: 'A listagem abaixo representa os IPS de acesso externo baseado na regra aplicada no APP.',

  table_access_external_no_item: 'Nenhum item',

  button_new_content_group: 'Novo conteúdo',
  content_group_field_uid: 'Uid',
  content_group_field_name: 'Nome',
  content_group_field_thumb_url: 'Miniatura',
  content_group_field_created_at: 'Data de criação',
  content_group_field_updated_at: 'Data de atualização',
  table_managed_content_no_item: 'Nenhum conteúdo cadastrado',

  pending_message_deteted_content_group: 'Apagando conteúdo',
  success_message_deteted_content_group: 'Conteúdo removido',
  error_message_deteted_content_group: 'Falha ao remover o conteúdo',

  ////managed/content/connector/uid/-/new
  screen_managed_content_new: 'Novo conteúdo',
  register_managed_content: 'Cadastrar',
  managed_content_uid: 'Uid',
  managed_content_name: 'Nome',
  managed_content_description: 'Descrição',
  information_managed_content_name: 'Bla bla bla',

  screen_managed_content_edit: 'Conteúdo gerenciado - Edição',

  ///managed/content/group/uid/<uid>
  tab_managed_content_preview: 'Pré-Visualização',
  tab_managed_content_informations: 'Informações',
  tab_managed_content_categories: 'Categorias',
  tab_managed_content_contents: 'Conteúdos',
  tab_managed_content_products: 'Produtos',
  tab_managed_content_authorizations: 'Autorizações',
  tab_managed_content_reference_info : 'Informações',

  managed_content_detail_title: 'Grupo de conteúdo',
  managed_content_detail_description:
    'Informações básicas sobre o conteúdo cadastrado. Sua alteração reflete em produção depois aproximadamente 10 minutos.',
  managed_content_created_at: 'Data de criação',
  managed_content_updated_at: 'Data de atualização',

  managed_content_categories_title: 'Categorização do conteúdo',
  managed_content_categories_description:
    'Abaixo, selecione todas as categoria que o conteúdo se encontra. Com um conteúdo devidamente categorizado, mais fácil será o direcionamento para o usuário.',

  managed_content_button_link_content: 'Nova referência',
  managed_content_content_title: 'Conteúdos referênciados',
  managed_content_content_description:
    "Um conteúdo gerenciado, é formado por um ou mais arquivos. Sendo assim, selecione os arquivos desejados que devem ser referencias por este grupo de conteúdo, clicando 'Nova referência'.",

  managed_content_reference_modal_title_new: 'Conteúdo referenciado',

  managed_content_products_title: 'Vínculo de produtos',
  managed_content_products_description:
    'A listagem abaixo representa todos os produtos cadastrados na plataforma. Selecione todos os produtos que garatem o acesso do usuário ao conteúdo. Caso o usuário tenha um dos produtos selecionados, o acesso ao conteúdo será autorizado.',

  managed_content_reference_uid: 'Uid',
  managed_content_reference_name: 'Nome',
  managed_content_reference_description: 'Descrição',
  managed_content_reference_created_at: 'Data de criação',
  managed_content_reference_updated_at: 'Data de atualização',

  table_header_managed_content_content_uid: 'Uid',
  table_header_managed_content_content_name: 'Nome',
  table_header_managed_content_content_mime_type: 'Tipo de conteúdo',
  table_header_managed_content_content_description: 'Descrição',
  table_header_managed_content_content_created_at: 'Data de criação',
  table_header_managed_content_content_updated_at: 'Data de atualização',

  table_header_managed_content_authorization_uid: 'Uid',
  table_header_managed_content_authorization_role: 'Permissão',
  table_header_managed_content_authorization_created_at: 'Data de criação',
  table_header_managed_content_authorization_updated_at: 'Data de atualização',
  table_header_managed_content_authorization_authorize_anonymous_users:
    'Acesso público',
  table_header_managed_content_authorization_skip_required_products:
    'Ignorar produtos',

  managed_content_authorization_products : 'Produtos',
  managed_content_authorization_projects: 'Projetos',
  managed_content_authorization_modal_header_title: 'Autorização',
  managed_content_authorization_modal_title: 'Gestão de autorizações',
  managed_content_authorization_modal_description:
    "Uma autorização tem como objetivo direcionar o conteúdo para o público correto. Ao criar um novo registro, selecione os critério exigidos e clique em 'Atualizar'.",
  managed_content_authorization_roles: 'Permissão atribuída',
  managed_content_authorize_anonymous_users:
    'Autorizar acesso de usuários anônimos',
  managed_content_skip_required_products: 'Remover a necessidade dos produtos',

  button_update_managed_content: 'Atualizar',
  button_update_managed_content_reference : 'Atualizar',

  update_managed_content: 'Atulizar',

  project_field_uid: 'UID',
  project_field_name: 'Nome',
  project_field_created_at: 'Data de criação',
  project_field_updated_at: 'Data da atualização',
  update_managed_content: 'Atualizar',
  login_finding_user : 'verificando login...',
  login_user_not_found : 'login não encontrado',
  login_processing : 'autenticando...',
  login_error : 'falha ao autenticar',

  button_link_project : "Vincular projeto",
  button_link_user_project : "Atualizar vínculos",
  user_title_link_user_project : "Projetos vinculados ao usuário",
  tab_user_projects : 'Projetos',
  user_projects_edit_title : "Projetos vinculados",
  user_projects_edit_description : "Na listagem abaixo, consta todos os projetos que o usuário ativo está vinculado. Alem das informações referentes ao projeto, um aliás para o login do usuário vinculado ao projeto é detalhado na listagem.",
  table_header_project_uid : "uid",
  table_header_project_name : "nome",
  table_header_project_login_alias : "aliás",

  user_edit_project_alias : "Edição de aliás",
  user_login_alias : "Aliás do usuário para acesso ao projeto",
  button_update_project_alias : "Atualizar aliás",

  user_uid_option : "Atribuição direta para um usuário",

  pending_message_access_delete_content: 'Removendo conteúdo',
  success_message_access_delete_content: 'Conteúdo removido',
  error_message_access_delete_content: 'Falha ao remover conteúdo',

  pending_message_deteted_authorization: 'Removendo autorização',
  success_message_deteted_authorization: 'Autorização removida',
  error_message_deteted_authorization: 'Falha ao remover autorização',

  pending_message_new_project: 'Adicionando projeto',
  success_message_new_project: 'Projeto adicionado com sucesso',
  error_message_new_project: 'Falha ao adicionar projeto',

  pending_message_update_user_project: 'Atualizando vínculo',
  success_message_update_user_project: 'Vínculo do usuário atualizado',
  error_message_update_user_project: 'Falha ao atualizar vínculo do usuário',

  pending_message_create_managed_content_reference: 'Criando referência',
  success_message_create_managed_content_reference: 'Referência criada com sucesso',
  error_message_create_managed_content_reference: 'Falha ao criar referência',

  pending_message_update_managed_content_reference: 'Atualizado referência',
  success_message_update_managed_content_reference: 'Referência atualizada com sucesso',
  error_message_update_managed_content_reference: 'Falha ao atualizar referência',

};

export { textsPT };
