const Configuration = {
  getEntity: () => ({
    layout_configuration: {
      hide_event_period: true,
      hide_products_restriction: true,
    },
    configuration: [
      {
        field: 'Nome',
        identifier: 'name',
        type: 'input-text',
        required: true,
      },
      {
        field: 'Descrição',
        identifier: 'description',
        type: 'input-text',
        required: false,
      },
      {
        field: 'Path',
        identifier: 'path',
        type: 'input-text',
        required: true,
      },
    ],
  }),
};

export default Configuration;
