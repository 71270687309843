import React, { useState, useEffect } from 'react';
import { Header, Sidebar, Breadcrumb, Footer } from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import './forum.scss';

export default function ViewForum({ consumer, entity, history }) {
  const [preferences, setPreferences] = useState(null);

  useEffect(() => {
    
    // handleMultiDelete();
    init()
    consumer.defineRoute({ name: 'Fórum', uri: '/forum' });
  }, []);

  async function init() {
    const res = await fetch('./preferences.json');
    const prefs = await res.json();
    setPreferences(prefs);
  }

  if (preferences) {
    return <>
      <Header />
      <Sidebar
        currentRoute={consumer ? consumer.currentRoute : null}
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        defineRoute={consumer ? consumer.defineRoute : null}
      />
      <div class="rdp-admin-forum-container">
        <iframe src="https://euconquisto-forum.digitalpages.com.br/admin"/>
      </div>
      <Footer />
    </>
  } else {
    return "";
  }
}
