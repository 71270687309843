import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Header, Sidebar, Breadcrumb, Footer } from '../../components';
import DynamicForm from '../../components/form-dynamic-v2';
import Loading from '../../components/loading';

export default function NewProfile({ consumer, history }) {
  const [entity, setEntity] = useState();

  const getForm = async () => {
    const res = await fetch('./preferences.json');
    const { forms } = await res.json();
    setEntity(forms['new-profile']);
  };

  useEffect(() => {
    getForm();
  }, []);

  const handleSubmit = () => {
    console.log('handleSubmit clicked!');
  };

  return (
    <div>
      <Header />
      <Sidebar
        currentRoute="/profiles/new"
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
      <div className="main-content-profile">
        {entity ? (
          <DynamicForm
            handleSubmit={handleSubmit}
            form={entity}
            submitText={entity ? entity.submitText : 'Submit'}
          />
        ) : (
          <Loading msg="Carregando Formulário" />
        )}
      </div>
      <Footer />
    </div>
  );
}
