import React, { useState, useEffect } from 'react';
import {
  Header,
  Sidebar,
  Breadcrumb,
  FormDynamic,
  NotifyBox,
  Footer,
} from '../../components';
import Loading from '../../components/loading';
import './styles.scss';
import RDP_CONFIG from '../../config/config';
import { formatBody } from '../../utils/formatBody';
import './styles.scss';

export default function NewAdmin({ consumer, history }) {
  const [entity, setEntity] = useState();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');

  async function getFormFields() {
    const response = await fetch('./preferences.json');

    const { forms } = await response.json();

    setEntity(forms['new-administrator']);
  }

  useEffect(() => {
    getFormFields();

    consumer.defineRoute({ name: 'Novo Usuário', path: 'users/new' });
  }, []);

  async function handleSubmit(dataForm) {
    console.log(dataForm);
  }

  return (
    <>
      {entity ? (
        <div className="rdp-admin-new-entity">
          <Header />
          <Sidebar
            currentRoute="/users/new"
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
          <div id="rdp-admin-content-area" className="rdp-admin-content">
            {loadingSubmit && <Loading msg="Sending..." />}
            {!loadingSubmit && msgSuccess && (
              <NotifyBox
                type="success"
                onClose={() => setMsgSuccess('')}
                message={msgSuccess}
              />
            )}
            {!loadingSubmit && msgError && (
              <NotifyBox
                type="error"
                onClose={() => setMsgError('')}
                message={msgError}
              />
            )}
            <div className="test">
              {entity && (
                <FormDynamic
                  blocked={false}
                  submitText={entity.submitText}
                  isNew={false}
                  cleanValues={false}
                  handleSubmit={(e) => handleSubmit(e.data)}
                  entity={entity}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading msg="Setting up the form..." />
      )}
      <Footer />
    </>
  );
}
