import React, { Component } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';
import Configuration from './list-iot-group.config';
import { GenericList } from '../../builders/list';
import { Redirect } from 'react-router-dom';
import { result } from 'lodash-es';

export default class ViewIotList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preloader: true,
      loadingSubmit: null,
      messages: {
        success: null,
        error: null,
      },
      redirectTo: null,
      filters: {
        page: 1,
      },
      body: [],
      seletedElementToDelete: [],
      elements: {},
      values: {},
      msgSuccess: null,
      loading: false,
      listEntity: {},
    };
    this.deleteProducts = this.deleteProducts.bind(this);
  }

  componentDidMount() {
    this.getDevices();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.entityId !== prevProps.match.params.entityId) {
      this.setState(
        {
          filters: {
            order_field: 'created_at',
            order_type: 'desc',
            status: '',
            start_at: '',
            finish_at: '',
            text: '',
            page: 1,
          },
        },
        () => {
          this.getDevices();
        }
      );
      this.setState({
        seletedElementToDelete: [],
      });
    }
  }

  getItemsSchema = async (selected = {}) => {
    let selectedValue = selected.value;
    let selectedLabel = selected.label;

    if (!selectedValue) {
      const initialSelected = this.state.selectOptions[0][0];
      selectedValue = initialSelected.value;
      selectedLabel = initialSelected.label;
    }

    const data = await Sdk.Api.authorization.entity.schemaItems(selectedValue, {
      noCache: true,
    });
    const elements = {
      result: data.map((item) => ({
        ...item,
        ...item.data,
      })),
      current_page: 1,
      page_count: 1,
      page_size: data.length,
      row_count: data.length,
    };

    this.setState(
      {
        customNewOptionTitle: `Adicionar ${selectedLabel}`,
        elements,
        selectOptions: [
          this.state.selectOptions[0].map((option) => ({
            ...option,
            active: option.value === selectedValue,
          })),
        ],
        messages: {
          noContent:
            elements.result.length == 0
              ? 'Nenhum dispositivo cadastrado'
              : null,
        },
      },
      () => {
        this.setState({ preloader: false });
      }
    );
  };

  getDevices = async () => {
    this.setState(
      {
        preloader: true,
      },
      async () => {
        const selectOptions = await Sdk.Api.authorization.entity.entitySchemasAvailableInProject();
        this.setState(
          {
            selectOptions: [
              selectOptions.map((option, index) => ({
                label: option.name,
                value: option.uid,
                active: index == 0,
                data: option.properties,
                type: 'schema',
              })),
            ],
          },
          this.getItemsSchema
        );
      }
    );
  };

  redirectToNew = () => {
    if (this.state.selectOptions && this.state.selectOptions.length) {
      const selected = this.state.selectOptions[0].find(
        (option) => option.active
      );

      return this.setState({
        redirectTo: `/iot/groups/schema/${selected.value}/entity/new`,
      });
    }
  };

  onUpdate = (uid) => {
    if (this.state.selectOptions && this.state.selectOptions.length) {
      const selected = this.state.selectOptions[0].find(
        (option) => option.active
      );

      return this.setState({
        redirectTo: `/iot/groups/schema/${selected.value}/entity/${uid}`,
      });
    }
  };

  onDelete = (values) => {
    const success = () => {
      this.setState(
        {
          preloader: false,
        },
        () => this.getDevices(true)
      );
    };

    this.setState(
      {
        preloader: true,
      },
      () => {
        if (Array.isArray(values)) {
          Promise.all(
            values.map((uid) =>
              Sdk.Api.authorization.entity.deleteSchemaItem(uid)
            )
          ).then(() => success());
        } else {
          Sdk.Api.authorization.entity
            .deleteSchemaItem(values)
            .then(() => success());
        }
      }
    );
  };

  async deleteProducts(uids) {
    const products = this.state.elements.result;

    for (const index in uids) {
      const uid = uids[index];
      const product = products.find((p) => p.uid == uid);

      if (!product) continue;

      await Sdk.store.deleteProduct(product);
    }

    this.setState(
      {
        preloader: true,
        seletedElementToDelete: [],
        messages: {
          success: 'Produto(s) removido(s)',
          error: null,
        },
      },
      this.getCourses
    );
  }

  closeMessage = (type) => {
    this.setState({
      messages: {
        ...this.state.messages,
        [type]: null,
      },
    });
  };

  render() {
    const {
      messages,
      elements,
      preloader,
      redirectTo,
      listEntity,
      selectOptions,
    } = this.state;

    const { entity } = this.props.match.params;
    const { consumer } = this.props;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <GenericList
        consumer={consumer}
        elements={elements}
        messages={messages}
        fields={Configuration.fields}
        options={Configuration.options}
        breadcrumb={{
          selectOptions: selectOptions,
          handleOptionSelected: (value) => {
            this.getItemsSchema(value);
          },
          routeNew: () => this.redirectToNew(),
          label: entity,
          customNewOptionTitle: this.state.customNewOptionTitle,
        }}
        loading={{
          preloader,
        }}
        actions={{
          closeMessage: this.closeMessage,
          onUpdate: this.onUpdate,
          onDelete: this.onDelete,
        }}
      />
    );
  }
}
