import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './dropzone-upload.scss';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { DAMService } from '../../services/dam';
import { IconCloseModal, IconPreview, IconCancelUpload } from '../icon';
import RDP_UTILS from '../../config/util';
import PreviewModal from '../preview-modal';
import { UserService } from '../../services/user';

const DropzoneUpload = (props) => {
  const maxSize = 1000048576;
  const [uploadedFiles, setUploadFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [modalPreview, setModalPreview] = useState(false);
  const [uidPreview, setUidPreview] = useState(null);

  useEffect(() => {
    if (props.cleanValues) {
      setUploadFiles([]);
      setFiles([]);
    }
  }, [props]);

  const openPreview = (uid) => {
    setUidPreview(uid);
    setModalPreview(true);
  };

  const closePreview = () => {
    setUidPreview(null);
    setModalPreview(false);
  };

  const uploadCompleted = (data, file) => {
    let fileData = data[0];
    let uid = undefined;

    if (fileData.metadatas) {
      uid = fileData.metadatas.content_uid;
    }
    if (fileData.document) {
      uid = fileData.document.content_uid;
    }

    fileData.url = RDP_UTILS.urlRDPContentToSave(uid);
    fileData.urlPreview = RDP_UTILS.urlRDPContent(uid);
    const files = uploadedFiles;
    file.uid = uid;
    file.isContent = file.name.indexOf('.zip') !== -1;
    files.push(file);
    const dataToSend = { uid, url: fileData.url };
    setUploadFiles(files);
    props.onUpload(props.id, dataToSend);
  };

  const removeCompleted = (fileName) => {
    const upfiles = files.filter((file) => {
      if (file.name != fileName) return file;
    });
    setFiles(upfiles);
  };

  const removeAttached = (fileName) => {
    const files = uploadedFiles.filter((file) => {
      if (file.name != fileName) return file;
    });
    setUploadFiles(files);
    removeCompleted(fileName);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: props.accept ? props.accept : '.zip',
    minSize: 0,
    maxSize,
    multiple: props.multiple ? props.multiple : false,
  });

  const renderBadge = (type) => <span className="file-badge-type">{RDP_UTILS.typeFile(type)}</span>;

  // const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  return (
    <div className="container-dropzone-upload">
      <div className="dropzone-area" {...getRootProps()}>
        <input {...getInputProps()} />
        {!isDragActive && props.subtitle
          ? props.subtitle
          : 'Selecione o arquivo para upload.'}
        {isDragActive && !isDragReject && 'Iniciando upload.'}
        {isDragReject && 'Tipo do arquivo inválido!'}
        {/* {isFileTooLarge && (
          <div className="text-danger mt-2">
            File is too large.
          </div>
        )} */}
      </div>
      {files.length > 0 && (
        <p className="list-files-title">
          Fazendo o upload, Por favor aguarde...
        </p>
      )}
      <ul className="list-upload-files">
        {files.length > 0
          && files.map((uploadFile) => (
            <UploadItem
              file={uploadFile}
              onCompleted={uploadCompleted}
              renderBadge={renderBadge}
            />
          ))}
      </ul>

      {uploadedFiles.length > 0 && (
        <p className="list-files-title">
          Arquivos Anexados (
          {uploadedFiles.length}
          )
        </p>
      )}
      <ul className="list-files">
        {uploadedFiles.length > 0
          && uploadedFiles.map((acceptedFile) => (
            <AttachedItem
              remove={removeAttached}
              onPreview={openPreview}
              renderBadge={renderBadge}
              file={acceptedFile}
            />
          ))}
      </ul>
      <PreviewModal
        open={modalPreview}
        onClose={closePreview}
        uidContent={uidPreview}
      />
    </div>
  );
};
export default DropzoneUpload;

const UploadItem = (props) => {
  const { file } = props;
  const [progress, setProgress] = useState('0%');
  const [upStarted, setUpStarted] = useState(false);
  const [upError, setError] = useState(false);
  const [upSuccess, setSuccess] = useState(false);
  const CancelToken = Axios.CancelToken.source();

  useEffect((cancelReq) => {
    const upload = async () => {
      setUpStarted(true);
      const config = {
        progress: true,
        cancelToken: CancelToken.token,
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setProgress(`${percentCompleted}%`);
        },
      };

      const result = await DAMService.uploadFileAxios(file, config);
      if (result.status == 200) {
        setSuccess(true);
        props.onCompleted(result.data, file);
      } else {
        setProgress(`Erro:${result.status}`);
        setError(true);
      }
    };

    if (upStarted == false) upload();
  });

  let styleItem = 'item-upload ';
  if (upError) styleItem += 'error-file';
  if (upSuccess) styleItem += 'success-file';
  return (
    <li className={styleItem}>
      <div className="file-data">
        {props.renderBadge(file.type)}
        <span className="file-name">
          {file.name.length > 60 ? `${file.name.slice(0, 57)}...` : file.name}
        </span>
      </div>
      <div className="file-options">
        <span className="progress-upload">{progress}</span>
        <span className="file-size">{RDP_UTILS.formatBytes(file.size, 2)}</span>
        <Link
          to="#!"
          onClick={() => console.log('CANCEL TOKEN', CancelToken.cancel())}
          className="option-remove"
        >
          <IconCancelUpload />
        </Link>
      </div>
    </li>
  );
};

const AttachedItem = (props) => {
  const { file } = props;

  return (
    <li className="ready-file">
      {props.renderBadge(file.type)}
      <span className="file-name">
        {file.name.length > 60 ? `${file.name.slice(0, 57)}...` : file.name}
      </span>
      <div className="file-options">
        <span className="file-size">{RDP_UTILS.formatBytes(file.size, 2)}</span>
        {props.file.isContent && (
          <Link
            to="#!"
            onClick={() => props.onPreview(file.uid)}
            className="file-option option-preview"
          >
            <IconPreview />
          </Link>
        )}
        <Link
          to="#!"
          onClick={() => props.remove(file.name)}
          className="file-option option-remove"
        >
          <IconCloseModal />
        </Link>
      </div>
    </li>
  );
};
