import React from 'react';
import Components from 'rdp-builder-components';
import './styles.scss';

export default function Footer() {
  return (
    <div className="footer">
      <Components.Footers.DefaultFooter />
    </div>
  );
}
