import React, { Component } from 'react';
import './breadcrumb.scss';
import ButtonDynamic from '../button-dynamic/button-dynamic';
import ButtonGrey from '../button-grey/button-grey';
import history from '../../services/history';
import { Link } from 'react-router-dom';
import { IconTrash } from '../icon';
import RDP_UTILS from '../../config/util';
import Select from 'react-select';

const BD_TITLES = {
  dashboard: 'Dashboard',
  users: 'Gestão de usuários',
};

export default class Breadcrumb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaitingDownloadUsersDataEnabled: false,
      isWaitingDownloadScoresDataEnabled: false,
      isWaitingDownloadFrequenciesDataEnabled: false,
      isWaitingGenerateAppSignupLinkEnabled: false,
      isWaitngDownloadCertificateEnabled: false,
      frequenciesOption: "dados-basicos"
    }
  }

  setIsWaitingDownloadScoresDataEnabled = (value) => {
    this.setState({ isWaitingDownloadScoresDataEnabled: value });
  }

  redirectToDashboard = () => {
    window.location = window.location.origin + window.location.pathname;
    // if (this.props.defineRoute) this.props.defineRoute(null);
    // RDP_UTILS.redirectTo();
  };

  redirectBack = () => {
    if (this.props.backRoute) history.push(this.props.backRoute);
  };

  renderTitle() {
    let title = 'Página';
    let rota = window.location.hash;
    // if (rota === '#/') {
    //   title = BD_TITLES.dashboard;
    // } else if (rota.indexOf('users') !== -1) {
    //   title = BD_TITLES.users;
    // }
    title = BD_TITLES.dashboard;
    return title;
  }

  render() {
    const {
      newOption,
      deleteOption,
      expandOption,
      currentBreadcrumbTitle,
      currentRoute,
      currentGroup,
      backRoute,
      updateMode,
      options,
      optionSelected,
      customNewOptionTitle,
      importCsvEnabled = false,
      importCsvOnClick = function () {},
      importCsvOfferRegistrationEnabled = false,
      importCsvOfferRegistrationOnClick = function () {},
      importDownloadCsvModelEnabled = false,
      importDownloadCsvModelOnClick = function () {},
      importDownloadXlsxModelEnabled = false,
      importDownloadXlsxModelOnClick = function () {},
      importDownloadScoresDataEnabled = false,
      importDownloadScoresDataOnClick = async function () {},
      importDownloadFrequenciesDataEnabled = false,
      importDownloadFrequenciesDataOnClick = async function () {},
      generateEntityAppSignupLinkEnabled = false,
      generateEntityAppSignupLinkOnClick = async function () {},
      importDownloadCertificateEnabled = false,
      importDownloadCertificateOnClick = async function () {},
      onDownloadUsersCsv,
      onDownloadUsersXlsx,
      newBtnLabel,
    } = this.props;
    const menuOptions =
      options &&
      options.map((option) => {
        let activeItem = option && option.find((v) => v.active == true);

        return (
          <Select
            placeholder={activeItem && activeItem.label}
            defaultValue={activeItem}
            options={option}
            styles={RDP_UTILS.MultipleSelect.styleFilter}
            isSearchable={false}
            onChange={(value) => optionSelected(value)}
            value={activeItem && activeItem.label}
            theme={(theme) => ({
              ...theme,
              borderRadius: 6,
              borderColor: '#fff',
              colors: {
                ...theme.colors,
                primary25: '#c7a5f5',
                primary: '#cccccc',
              },
            })}
          />
        );
      });

    let buttonNewTitle = newBtnLabel || `Criar ${
      currentRoute ? currentRoute.name : 'Entidade'
    }`;
    if (customNewOptionTitle) buttonNewTitle = customNewOptionTitle;

    return (
      <div className="rdp-admin-component-breadcrumb">
        <div className="breadcrumb-content">
          <div className="breadcrumb-content-title">
            {backRoute &&
              <Link to={backRoute} className="breadcrumb-btn-back">
                {'<'} Voltar
              </Link>
            }
            {/* {backRoute ? (
              <Link to={backRoute} className="breadcrumb-btn-back">
                {'<'} Voltar
              </Link>
            ) : (
              <span
                className={`rdp-admin-component-breadcrumb-icon ${
                  currentGroup && currentGroup.icon ? currentGroup.icon : 'home'
                }`}
              ></span>
            )} */}

            <span className="rdp-admin-component-breadcrumb-label">
              {currentBreadcrumbTitle || (currentRoute ? currentRoute.name : this.renderTitle())}
              {updateMode && <strong>(Edição)</strong>}
            </span>
          </div>

          <div className="breadcrumb-content-options">
            {menuOptions}
            {importDownloadCsvModelEnabled &&
              <ButtonGrey
                label="Baixar modelo de CSV"
                icon="download"
                onClick={() => importDownloadCsvModelOnClick()}
              />
            }
            {importDownloadXlsxModelEnabled &&
              <ButtonGrey
                label="Baixar modelo de XLSX"
                icon="download"
                onClick={() => importDownloadXlsxModelOnClick()}
              />
            }
            {importCsvEnabled &&
              <ButtonGrey
                label="Importar usuários(csv)"
                icon="upload"
                onClick={() => importCsvOnClick()}
              />
            }
            {importCsvOfferRegistrationEnabled &&
              <ButtonGrey
                label="Importar matrículas(csv)"
                icon="upload"
                onClick={() => importCsvOfferRegistrationOnClick()}
              />
            }
            {onDownloadUsersCsv && (
              <ButtonGrey
                label={this.state.isWaitingDownloadUsersDataEnabled ? 'Aguarde...' : "Baixar Usuários (CSV)"}
                icon="download"
                disabled={this.state.isWaitingDownloadUsersDataEnabled}
                onClick={() => {
                  if (this.state.isWaitingDownloadUsersDataEnabled) return;
                  // this.setState({ isWaitingDownloadUsersDataEnabled: false })
                  // this.setIsWaitingDownloadScoresDataEnabled(true);
                  onDownloadUsersCsv();
                }}
              />
            )}
            {onDownloadUsersXlsx && (
              <ButtonGrey
                label={this.state.isWaitingDownloadUsersDataEnabled ? 'Aguarde...' : "Baixar Usuários (XLS)"}
                icon="download"
                disabled={this.state.isWaitingDownloadUsersDataEnabled}
                onClick={() => {
                  if (this.state.isWaitingDownloadUsersDataEnabled) return;
                  // this.setState({ isWaitingDownloadUsersDataEnabled: false })
                  // this.setIsWaitingDownloadScoresDataEnabled(true);
                  onDownloadUsersXlsx();
                }}
              />
            )}
            {importDownloadScoresDataEnabled &&
              <ButtonGrey
                label={this.state.isWaitingDownloadScoresDataEnabled ? 'Aguarde...' : "Baixar quadro de notas CSV"}
                icon="download"
                disabled={this.state.isWaitingDownloadScoresDataEnabled}
                onClick={() => {
                  if (this.state.isWaitingDownloadScoresDataEnabled) return;
                  this.setIsWaitingDownloadScoresDataEnabled(true);
                  importDownloadScoresDataOnClick().then(() => this.setIsWaitingDownloadScoresDataEnabled(false));
                }}
              />
            }
            {importDownloadCertificateEnabled &&
              <ButtonGrey
                label={this.state.isWaitngDownloadCertificateEnabled ? 'Aguarde...' : "Visualizar certificado comprobatório"}
                icon="eye"
                disabled={this.state.isWaitngDownloadCertificateEnabled}
                onClick={() => {
                  if (this.state.isWaitngDownloadCertificateEnabled) return;
                  this.setState({ isWaitngDownloadCertificateEnabled: true });
                  importDownloadCertificateOnClick().then(() => this.setState({ isWaitngDownloadCertificateEnabled: false }));
                }}
              />
            }
            {importDownloadFrequenciesDataEnabled &&
              <ButtonGrey
                label={this.state.isWaitingDownloadFrequenciesDataEnabled ? 'Aguarde...' : "Baixar relatório de frequência CSV"}
                icon="download"
                disabled={this.state.isWaitingDownloadFrequenciesDataEnabled}
                radioOptions={[
                  {
                    "active": this.state.frequenciesOption === "dados-basicos",
                    "label": "Dados básicos dos usuários",
                    "value": "dados-basicos"
                  },
                  {
                    "active": this.state.frequenciesOption === "todos-os-dados",
                    "label": "Todos os dados dos usuários",
                    "value": "todos-os-dados"
                  }
                ]}
                onRadioOptionClick={(option) => this.setState({"frequenciesOption": option})}
                onClick={() => {
                  if (this.state.isWaitingDownloadFrequenciesDataEnabled) return;
                  this.setState({ isWaitingDownloadFrequenciesDataEnabled: true });
                  importDownloadFrequenciesDataOnClick(this.state.frequenciesOption === "todos-os-dados").then(() => this.setState({ isWaitingDownloadFrequenciesDataEnabled: false }));
                }}
              />
            }
            {generateEntityAppSignupLinkEnabled &&
              <ButtonGrey
                label={this.state.isWaitingGenerateAppSignupLinkEnabled ? 'Aguarde...' : "Gerar link de cadastro"}
                icon="link-variant"
                disabled={this.state.isWaitingGenerateAppSignupLinkEnabled}
                onClick={() => {
                  if (this.state.isWaitingGenerateAppSignupLinkEnabled) return;
                  this.setState({ isWaitingGenerateAppSignupLinkEnabled: true });
                  generateEntityAppSignupLinkOnClick().then(() => this.setState({ isWaitingGenerateAppSignupLinkEnabled: false }));
                }}
              />
            }
            {newOption && currentRoute && (
              <ButtonDynamic
                disabled={false}
                color="blue"
                icon="plus"
                actionClick={newOption}
                iconDiv={true}
              >
                {buttonNewTitle}
              </ButtonDynamic>
            )}
            {deleteOption && (
              <button
                data-tip="Excluir"
                onClick={deleteOption}
                className="btn-delete-notice"
              >
                <IconTrash />
              </button>
            )}
            {expandOption &&
              <span className="breadcrumb-expand-btn" onClick={expandOption}></span>
            }
          </div>
        </div>
      </div>
    );
  }
}
