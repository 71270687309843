import axios from 'axios';
import SDK from 'api.digitalpages.module.sdk.api';
import RDP_CONFIG from '../config/config';
import { UserService } from './user';

const api = axios.create({
  baseURL: RDP_CONFIG.apiURL,
});

// Request Interceptor
api.interceptors.request.use(
  (config) => {
    const user = SDK.Api.authorization.credential;
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: user ? `${user.type} ${user.accessToken}` : '',
      'Project-Key': SDK.Api.authorization.projectKey,
      'Api-Env': SDK.Api.enviroment,
    };
    return config;
  },
  (error) => Promise.reject(error),
);

// Response Interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log('UNAUTHORIZED USER');
      localStorage.clear();
    }
    return Promise.reject(error);
  },
);

export default api;
