import React from 'react';
import './modal-create-scene.scss';
import Modal from 'react-responsive-modal';
import { IconCloseModal } from '../icon';
import { FormDynamic } from '..';

const ModalFormDynamic = ({ open, title, onClose, formProps }) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      open={open}
      styles={{
        overlay: { backgroundColor: 'rgba(125, 125, 125, 0.2)' },
        modal: { borderRadius: '5px', padding: '0px' },
      }}
      showCloseIcon={false}
      focusTrapped={false}
      center
    >
      <div className="rdp-modal-container">
        <div className="rdp-modal-container-header">
          <div className="header-left">
            <span>{title}</span>
          </div>
          <button
            type="button"
            className="rdp-modal-close"
            onClick={onClose}
          >
            <IconCloseModal />
          </button>
        </div>

        <div className="rdp-modal-scene-container-content">
          <FormDynamic {...formProps}/>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFormDynamic;
