import React, { useEffect, useState } from 'react';
import {
  Header,
  Sidebar,
  Breadcrumb,
  FormDynamic,
  NotifyBox,
  Footer,
} from '../../components';
import ButtonDynamic from '../../components/button-dynamic/button-dynamic';
import DynamicForm from '../../components/form-dynamic-v2';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import { formatBody } from '../../utils/formatBody';

import './edit-product.scss';

export default function EditProduct({ consumer, match, history }) {
  const [entity, setEntity] = useState();
  const [entityUpd, setEntityUpd] = useState();
  const [defaultValues, setDefaultValues] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');

  async function getFormFields() {
    const response = await fetch('./preferences.json');

    const { forms } = await response.json();

    setEntity(forms['new-product']);
  }

  const loadProduct = async () => {
    try {
      const product = await Sdk.dynamic.bridge(
        `store/v1.0/product/uid/${match.params.productUid}`
      );
      const defaultValues = { ...product };
      defaultValues.installments_tax.forEach((tax, index) => {
        defaultValues[`installment-${index + 1}`] = tax;
      });
      setDefaultValues(defaultValues);
    } catch (error) {
      setMsgError(
        'Algo deu errado ao carregar o produto, tente novamente mais tarde'
      );
    }
  };

  useEffect(() => {
    getFormFields();

    consumer.defineRoute({ name: 'Editar Produto' });
  }, []);

  useEffect(() => {
    if (entity) {
      loadProduct();
    }
  }, [entity]);

  async function handleSubmit(data) {
    setLoadingSubmit(true);
    const { activeProject } = Sdk.Api.authorization;

    let connectors = activeProject.connectors;
    const paymentConnector = connectors.find((connector) =>
      connector.type.toLowerCase().includes('payment')
    );
    if (!paymentConnector) {
      setLoadingSubmit(false);
      setMsgError('Conector de pagamento não encontrado');
      return;
    }
    const installmentsTax = [];
    Array.from(Array(parseInt(data.installments_max)).keys()).forEach(
      (item, index) => {
        installmentsTax.push(data[`installment-${index + 1}`]);
      }
    );

    const productDto = {
      name: data.name,
      unique_name: data.unique_name,
      type: data.type,
      amount: data.amount,
      currency: data.currency,
      installments_max: data.installments_max,
      installments_tax: installmentsTax,
      type: 'Consumable',
    };

    try {
      await Sdk.dynamic.bridge(
        `store/v1.0/product/uid/${data.uid}/management`,
        productDto,
        'PUT'
      );
      history.push('/products');
    } catch (error) {
      setLoadingSubmit(false);
      setMsgError('Erro ao atualizar');
    }
  }

  return (
    <>
      {entity ? (
        <div className="rdp-admin-list-content">
          <Header />
          <Sidebar
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb
            customNewOptionTitle="asds"
            currentRoute={consumer ? consumer.currentRoute : null}
          />
          <div id="rdp-admin-content-area" className="rdp-admin-content">
            {/* <div className="mail-btn">
              <ButtonDynamic
                disabled={false}
                right
                color="blue"
                actionClick={() => alert('email')}
                iconDiv={true}
              >
                send first access email
              </ButtonDynamic>
            </div> */}
            {loadingSubmit && <Loading msg="Salvando..." />}
            {!loadingSubmit && msgSuccess && (
              <NotifyBox
                type="success"
                onClose={() => setMsgSuccess('')}
                message={msgSuccess}
              />
            )}
            {!loadingSubmit && msgError && (
              <NotifyBox
                type="error"
                onClose={() => setMsgError('')}
                message={msgError}
              />
            )}
            <div className="test">
              {entity && defaultValues && (
                <DynamicForm
                  handleSubmit={handleSubmit}
                  form={entity}
                  submitText={'Salvar Produto'}
                  defaultValues={defaultValues}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading msg="Montando Formulário..." />
      )}
      <Footer />
    </>
  );
}
