import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import './preview-modal.scss';
import Modal from 'react-responsive-modal';
import { IconCloseModal, IconUpload } from '../icon';
import NotifyBox from '../notify-box';
import Loading from '../loading';
import RDP_UTILS from '../../config/util';

export default function PreviewModal({
  title,
  open,
  onClose,
  uidContent,
  urlContent,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [iframeRef, setIframe] = useState(null);
  const [urlPreview, setURL] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (uidContent) {
      setURL(RDP_UTILS.urlRDPContent(uidContent));
    } else {
      setURL(urlContent);
    }
  }, [open]);

  useEffect(() => {
    // console.log("IFRAME", iframeRef)
  }, [iframeRef]);

  const onLoadContent = (e) => {
    setIsLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      styles={{
        overlay: { backgroundColor: 'rgba(125, 125, 125, 0.2)' },
        modal: {
          borderRadius: '4px',
          padding: '20px',
          maxWidth: '100%',
          position: 'absolute',
          padding: '0px',
          top: '84px',
          bottom: '5px',
          left: '5px',
          backgroundColor: '#f5f7f8',
        },
      }}
      showCloseIcon={false}
      focusTrapped={false}
      center
    >
      <div className="rdp-modal-preview-container">
        <div className="rdp-modal-container-header">
          <div className="header-left">
            <div className="icon">
              <IconUpload />
            </div>
            <span>Preview de Conteúdo</span>
          </div>
          <button
            type="button"
            className="rdp-modal-close"
            onClick={() => onClose()}
          >
            <IconCloseModal />
          </button>
        </div>
        <div className="rdp-modal-container-content">
          <div className="galerie-content">
            {isLoading && <Loading msg="Carregando conteúdo..." />}
            {urlPreview ? (
              <iframe
                ref={setIframe}
                onLoad={onLoadContent}
                title="Preview-Conteudo"
                src={urlPreview}
              />
            ) : (
              <NotifyBox
                type="error"
                message="Não foi possivel abrir o conteúdo, aguarde um pouco para tentar novamente."
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
