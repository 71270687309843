import React, { Component } from 'react';
import { IconFilterArrowUp, IconFilterArrowDown } from '../icon';
import './table-order-arrows.scss';

export default class TableOrderArrows extends Component {
  getClassName(isActive) {
    if (isActive) {
      return 'arrow active';
    }

    return 'arrow';
  }

  render() {
    const arrowUpActive = this.props.activeArrow === 'up';
    const arrowDownActive = this.props.activeArrow === 'down';

    return (
      <div className="rdp-filter-arrows">
        <div className={`${this.getClassName(arrowUpActive)} up`}>
          <IconFilterArrowUp />
        </div>
        <div className={this.getClassName(arrowDownActive)}>
          <IconFilterArrowDown />
        </div>
      </div>
    );
  }
}
