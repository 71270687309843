import React, { Component } from 'react';
import csvToJson from 'convert-csv-to-json';
import './list-users.scss';
import Sdk from 'api.digitalpages.module.sdk.api';
import { GenericList } from '../../builders/list';
import { Sidebar, Breadcrumb, Header, Footer, LoadingFullscreen } from '../../components';
import Loading from '../../components/loading';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { downloadUsersAsCsv } from './list-users-csv';
import { downloadUsersAsXlsx } from './list-users-xlsx';
import moment from 'moment-timezone';

export default class ViewUserList extends Component {
  constructor(props) {

    super(props);
    this.state = {
      preloader: true,
      canAdd: false,
      loadingSubmit: null,
      configuration: {},
      messages: {
        success: null,
        error: null,
      },
      redirectTo: null,
      filters: {
        page: 1,
      },
      body: [],
      seletedElementToDelete: [],
      elements: {},
      upload_csv_uploading: false,
      upload_csv_success: false,
      upload_csv_error: false
    };
    this.deleteUsers = this.deleteUsers.bind(this);
    this.csvInputRef = React.createRef();
  }

  componentDidMount() {
    if (Sdk.authorization.user) this.getUsers();
    this.getConfig();
    if (Sdk.authorization.user) this.checkRoles(Sdk.authorization.user.roles);
    this.props.consumer.defineRoute({ name: 'Gestão de usuários', uri: '/users' });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.entityId !== prevProps.match.params.entityId) {
      this.setState(
        {
          filters: {
            order_field: 'created_at',
            order_type: 'desc',
            status: '',
            start_at: '',
            finish_at: '',
            text: '',
            page: 1,
          },
        },
        () => {
          if (Sdk.authorization.user) this.getUsers();
        }
      );
      this.setState({
        seletedElementToDelete: [],
      });
    }
  }

  async getConfig() {
    this.setState({ preloader: true });

    const response = await fetch('./preferences.json');

    const { tables } = await response.json();

    this.setState({
      preloader: false,
      config: tables.users,
    });
  }

  async getUsers() {
    this.setState({ preloader: true });

    const result = await Sdk.Api.authorization.usersByProject();

    const tempStructure = {
      result: result.map(user => {
        user.updated_at = moment(user.updated_at+"Z").local()
        return user;
      }),
      current_page: 1,
      page_count: 1,
      page_size: result.length,
      row_count: result.length,
    };

    this.setState({
      elements: tempStructure,
      preloader: false,
      messages: {
        noContent: result.length == 0 ? 'No user registered' : null,
      },
    });
  }

  redirectToNew = () => {
    return this.setState({ redirectTo: `/users/new` });
  };

  onUpdate = (uid) => {
    return this.setState({ redirectTo: `/users/${uid}` });
  };

  handleMultiDelete = (id) => {
    this.setState(
      {
        deleteModal: false,
        loadingSubmit: 'Apagando dados...',
      },

      () => (Array.isArray(id) ? this.deleteUsers(id) : this.deleteUser(id))
    );
  };

  async deleteUsers(uids) {
    uids.forEach((uid) => {
      Sdk.dynamic.bridge(`auth/v1.0/user/uid/${uid}`, null, 'DELETE');
    });
  }

  async deleteUser(uid) {
    Sdk.dynamic.bridge(`auth/v1.0/user/uid/${uid}`, null, 'DELETE');
  }

  closeMessage = (type) => {
    this.setState({
      messages: {
        ...this.state.messages,
        [type]: null,
      },
    });
  };

  async checkRoles(items) {
    if (!items) return false;

    const roles = ['AdministratorGlobal', 'UserAdmin'];
    const restrictedRole = 'TeamAdmin';

    const userRoles = items.map((item) => item.type);

    const hasRestrictedRole = userRoles.includes(restrictedRole);
    const canEdit = !hasRestrictedRole && userRoles.some((r) => roles.includes(r));

    this.setState({ canAdd: canEdit });
  }

  importCsvOnClick() {
    this.csvInputRef.current.click();
  }

  onCsvFileChange(evt) {
    var _this = this;

    _this.setState({
      "upload_csv_uploading": true
    })

    var url = `${Sdk.Api.domain}/auth/v1.0/csv/import`;
    var fd = new FormData();
    var files = evt.currentTarget.files[0];
    var file = files;

    const removeColumnsWithPropPrefixFromFile = (file) => {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function(e) {
          var csv = e.target.result;
          var lines = csv.split("\n");
          var result = [];
          var headers = lines[0].split(";");
          for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(";");
            for (var j = 0; j < headers.length; j++) {
              if (headers[j].startsWith("prop.")) continue;
              obj[headers[j].trim()] = currentline[j];
            }
            result.push(obj);
          }
          resolve(result);
        };
        reader.onerror = function(e) {
          reject(e);
        };
        reader.readAsText(file);
      });
    }

    removeColumnsWithPropPrefixFromFile(files).then((result) => {
      const array = [Object.keys(result[0])].concat(result)

      let csv =  array.map(it => {
        const values = Object.values(it).toString()
        const length = values
          .replaceAll(',', '')
          .replaceAll(';', '')
          .replaceAll('\r', "")
          .length
        return length ? values : null
      }).filter(f => f !== null).join('\n')

      csv = csv.replaceAll(';', ',');

      let BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
      csv = new Blob([BOM, csv], {type: "text/csv"});
      const csvFile = new File([csv], "users.csv", {type: "text/csv"})
      fd.append('users.csv', csvFile, "users.csv")

      $.ajax({
        url: url,
        type: "POST",
        data: fd,
        contentType: false,
        processData: false,
        headers: {
          "Authorization": `${Sdk.Api.authorization.credential.type} ${Sdk.Api.authorization.credential.accessToken}`,
          "Project-Key" : Sdk.Api.authorization.projectKey,
          "Api-Env" : Sdk.Api.enviroment
        },
        success: (response) => {
          setTimeout(() => {
            this.createDynamicCSVUserProps(file);
          }, 5000)
        },
        error: function (response) {
          setTimeout(function() {
            _this.setState({
              "upload_csv_uploading": false,
              "upload_csv_error": true
            })
          }, 3000)
        }
      });
    });
  }

  async createDynamicCSVUserProps(file) {
    if (file) {
      const users = await Sdk.Api.authorization.usersByProject();
      var r = new FileReader();
      r.onload = async (e) => {
        var contents = e.target.result;
        // get titles of CSV
        const parseCsvTitle = (csv) => {
          let lines = csv.split(/\r?\n/);
          const header = lines.shift().split(';');
          lines = lines.filter((line) => line.trim() !== ''); // remove empty lines
          return lines.map((line) => {
            const bits = line.split(';');
            let obj = {};
            header.forEach((h, i) => (obj[h] = bits[i])); // or use reduce here
            return obj;
          });
        };
        const titles = parseCsvTitle(contents);
        for (const title of titles) {
          const keys = Object.keys(title);
          const keysWithPropsPrefix = keys.filter((key) =>
            key.match(/^prop\./)
          );
          const userWithSameLogin = users.find((user) => {
            return user.login === title.login;
          });
          if (userWithSameLogin) {
            for (const keyWithPropsPrefix of keysWithPropsPrefix) {
              const name = keyWithPropsPrefix.replace('prop.', '');
              const propertyValue = title[keyWithPropsPrefix];
              if (propertyValue === null) {
                continue;
              }
              const propertyName = name.charAt(0).toUpperCase() + name.slice(1);
              const userProperties = await Sdk.dynamic.bridge(
                `auth/v1.0/user/uid/${userWithSameLogin.uid}/management/property`
              );
              const existingProperty = userProperties.find(
                (prop) => prop.name === propertyName
              );
              if (existingProperty) {
                // If the property already exists, update it with a PUT request
                try {
                  await Sdk.dynamic.bridge(
                    `auth/v1.0/user/management/property/uid/${existingProperty.uid}`,
                    {
                      name: name.charAt(0).toUpperCase() + name.slice(1),
                      regex_validation: null,
                      type: 'Text',
                      value: title[keyWithPropsPrefix],
                    },
                    'PUT'
                  );
                  console.log(
                    `Property '${propertyName}' updated for user '${userWithSameLogin.login}'`
                  );
                } catch (error) {
                  console.log(
                    `Error updating property '${propertyName}' for user '${userWithSameLogin.login}':`,
                    error
                  );
                }
              } else {
                // Otherwise, create a new property with a POST request
                await Sdk.dynamic.bridge(
                  `auth/v1.0/user/uid/${userWithSameLogin.uid}/management/property`,
                  {
                    name: name.charAt(0).toUpperCase() + name.slice(1),
                    regex_validation: null,
                    type: 'Text',
                    value: title[keyWithPropsPrefix],
                  },
                  'POST'
                );
              }
            }
          }
        }
        this.setState({
          upload_csv_uploading: false,
          upload_csv_success: true,
        });
      };
      r.readAsText(file);
    }
  }

  onRefreshUsers() {
    var _this = this;

    this.setState({
      "upload_csv_uploading": false,
      "upload_csv_success": false
    }, function() {
      _this.getUsers()
    });
  }

  onCsvError() {
    this.setState({
      "upload_csv_uploading": false,
      "upload_csv_error": false
    });
  }

  render() {

    const { messages, elements, preloader, redirectTo, config, canAdd } =
      this.state;

    const { entity } = this.props.match.params;
    const { consumer } = this.props;

    if (canAdd && canAdd === false) {
      return null;
    }

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return config && elements?.result?.length >= 0 ? (
      <>
        <div className="rdp-manager-admin-csv-input-group">
          <input ref={this.csvInputRef} type="file" style={{display: `${window.top !== window.self ? 'none' : 'block'}`}} className="rdp-manager-admin-csv-input"  onChange={(e) => this.onCsvFileChange(e)}/>
        </div>
        <GenericList
          hideDelete
          enableLocalSearch={true}
          localSearchPlaceholder="Buscar usuário(s)"
          consumer={consumer}
          elements={elements}
          messages={messages}
          fields={config?.fields}
          options={config?.options}
          breadcrumb={{
            selectOptions: null,
            routeNew: canAdd ? '/users/new' : null,
            label: entity,
            customNewOptionTitle: config?.newButtonLabel,
            importCsvEnabled: true,
            importCsvOnClick: () => this.importCsvOnClick(),
            onDownloadUsersXlsx: () => {
              if (this.state.elements.result) {
                downloadUsersAsXlsx(this.state.elements.result);
              }
            }
          }}
          loading={{
            preloader,
          }}
          actions={{
            closeMessage: this.closeMessage,
            onUpdate: this.onUpdate,
          }}
        />

        <Footer/>

        {this.state.upload_csv_uploading &&
          <LoadingFullscreen
            renderType="uploading"
            uploadingMessage={"Realizando upload do CSV de usuários.<br>Aguarde alguns instantes..."}
          />
        }
        {this.state.upload_csv_success &&
          <LoadingFullscreen
            renderType="success"
            successMessage="CSV de usuários importado com sucesso!"
            successBtnLabel="Atualizar listagem de usuários"
            successBtnOnClick={() => this.onRefreshUsers()}
          />
        }
        {this.state.upload_csv_error &&
          <LoadingFullscreen
            renderType="error"
            errorMessage="Erro na importação dos usuários.<br>Verifique se o arquivo CSV está correto."
            errorBtnLabel="Fechar e tentar novamente"
            errorBtnOnClick={() => this.onCsvError()}
          />
        }
      </>
    ) : (
      <>
        <Header />
        <Sidebar
          currentRoute="/users/new"
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb 
          currentRoute={consumer ? consumer.currentRoute : null}
        />
        <div id="rdp-admin-list-users-area" className="rdp-admin-content">
          <Loading msg={config?.loadingText} />
        </div>
        <Footer />;
      </>
    );
  }
}
