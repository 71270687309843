import React, { Component } from 'react';
import './list-devices.scss';
import Sdk from 'api.digitalpages.module.sdk.api';
import Configuration from './list-devices.config';
import { GenericList } from '../../builders/list';
import { Redirect } from 'react-router-dom';

export default class ViewDevicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preloader: true,
      loadingSubmit: null,
      messages: {
        success: null,
        error: null,
      },
      redirectTo: null,
      filters: {
        page: 1,
      },
      body: [],
      seletedElementToDelete: [],
      elements: {},
      values: {},
      msgSuccess: null,
      loading: false,
    };
    this.deleteProducts = this.deleteProducts.bind(this);
  }

  componentDidMount() {
    this.getDevices();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.entityId !== prevProps.match.params.entityId) {
      this.setState(
        {
          filters: {
            order_field: 'created_at',
            order_type: 'desc',
            status: '',
            start_at: '',
            finish_at: '',
            text: '',
            page: 1,
          },
        },
        () => {
          this.getDevices();
        }
      );
      this.setState({
        seletedElementToDelete: [],
      });
    }
  }

  async getDevices(noCache = false) {
    this.setState({ preloader: true });

    const data = await Sdk.Api.iot.listDevices({ noCache });

    const elements = {
      result: data.result,
      current_page: data.current_page,
      page_count: 1,
      page_size: data.result.length,
      row_count: data.total_results,
    };

    this.setState({
      elements,
      preloader: false,
      messages: {
        noContent:
          elements.result.length == 0 ? 'Nenhum dispositivo cadastrado' : null,
      },
    });
  }

  redirectToNew = () => {
    return this.setState({ redirectTo: `/devices/new` });
  };

  onUpdate = (uid) => {
    return this.setState({ redirectTo: `/devices/${uid}` });
  };

  onDelete = (values) => {
    const success = () => {
      this.setState(
        {
          preloader: false,
        },
        () => this.getDevices(true)
      );
    };

    this.setState(
      {
        preloader: true,
      },
      () => {
        if (Array.isArray(values)) {
          Promise.all(
            values.map((uid) => Sdk.Api.iot.deleteDevice(uid))
          ).then(() => success());
        } else {
          Sdk.Api.iot.deleteDevice(values).then(() => success());
        }
      }
    );
  };

  onClone = (uid) => {
    this.setState(
      {
        preloader: true,
      },
      () => {
        Sdk.Api.iot.getDevice(uid).then((data) => {
          Sdk.Api.iot.createDevice(data.device).then(() => {
            this.setState(
              {
                preloader: false,
              },
              () => this.getDevices(true)
            );
          });
        });
      }
    );
  };

  handleMultiDelete = (id) => {
    this.setState(
      {
        deleteModal: false,
        preloader: true,
      },
      () => {
        this.deleteProducts(this.state.seletedElementToDelete);
      }
    );
  };

  async deleteProducts(uids) {
    const products = this.state.elements.result;

    for (const index in uids) {
      const uid = uids[index];
      const product = products.find((p) => p.uid == uid);

      if (!product) continue;

      await Sdk.store.deleteProduct(product);
    }

    this.setState(
      {
        preloader: true,
        seletedElementToDelete: [],
        messages: {
          success: 'Produto(s) removido(s)',
          error: null,
        },
      },
      this.getCourses
    );
  }

  closeMessage = (type) => {
    this.setState({
      messages: {
        ...this.state.messages,
        [type]: null,
      },
    });
  };

  render() {
    const { messages, elements, preloader, redirectTo } = this.state;

    const { entity } = this.props.match.params;
    const { consumer } = this.props;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <div className="dp-scene-list-content">
        <GenericList
          consumer={consumer}
          elements={elements}
          messages={messages}
          fields={Configuration.fields}
          options={Configuration.options}
          breadcrumb={{
            selectOptions: null,
            routeNew: '/devices/new',
            label: entity,
            customNewOptionTitle: 'Novo dispositivo',
          }}
          loading={{
            preloader,
          }}
          actions={{
            closeMessage: this.closeMessage,
            onUpdate: this.onUpdate,
            onDelete: this.onDelete,
            onClone: this.onClone,
          }}
        />
      </div>
    );
  }
}
