import React, { useEffect, useState } from 'react';
import './edit-layout-configuration.scss';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Footer,
  Loading,
  NotifyBox,
} from '../../components';
import Sdk from 'api.digitalpages.module.sdk.api';
import DynamicForm from '../../components/form-dynamic-v2';
import { DAMService } from '../../services/dam';
import { getConnectorFromArray } from '../../utils/getConnectorFromArray';
import { background } from 'styled-system';
import ButtonDynamic from '../../components/button-dynamic/button-dynamic';

export default function EditLayoutConfiguration({ consumer, history }) {
  const [entity, setEntity] = useState();
  const [categories, setCategories] = useState([]);
  const [defaultValues, setDefaultValues] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');

  const getForm = async () => {
    const res = await fetch('./preferences.json');
    const { forms } = await res.json();
    //const res = null;
    /* URL PARA CONSUMO DAS CONFIGURAÇÕES */

    var connectorUid = await Sdk.Api.authorization.activeProject.connectors.filter(connector => connector.type === "Customization_1")[0].uid;

    const result = await Sdk.dynamic.bridge(`auth/v1.1/connector/uid/${connectorUid}`, null, 'GET');
    const defaultConfigs = {
      headerBackground: "",
      sidebarActiveButtonBackgroundColor: "",
      sidebarButtonBackgroundColor: "",
      headerDropdownBackground: "",
      logotype: "",
      sidebarPosition: "",
      sidebarLabelColor: "",
      sidebarActiveLabelColor: "",
      headerFirstLabelColor: ""
    };

    console.log("res: ", result);
    result["configuration"]["configs"].map(
      async ({
        uid, 
        value
      }) => { 
            console.log("uid: ", uid);
            console.log("value: ", value);
        switch (uid) {
          case 'headerBackground':
            defaultConfigs.headerBackground = value;
            break;
          case 'sidebarActiveButtonBackgroundColor':
            defaultConfigs.sidebarActiveButtonBackgroundColor = value;
            break;
          case 'sidebarButtonBackgroundColor':
            defaultConfigs.sidebarButtonBackgroundColor = value;
            break;
          case 'sidebarPosition':
            defaultConfigs.sidebarPosition = value;
            break
          case 'headerDropdownBackground':
            defaultConfigs.headerDropdownBackground = value;
            break
          case 'headerLogotype':
            defaultConfigs.logotype = value;
            break;
          case 'sidebarLabelColor':
            defaultConfigs.sidebarLabelColor = value;
            break;
          case 'sidebarActiveLabelColor':
            defaultConfigs.sidebarActiveLabelColor = value;
            break;
          case 'headerFirstLabelColor':
            defaultConfigs.headerFirstLabelColor = value;
            break;
        }
      })

      //formsCp['fields'].push(defaultValues);

      setDefaultValues(defaultConfigs);

    setEntity(forms['edit-layout-configuration']);
  };

  const loadEntities = async () => {
    //var connector_uid = Sdk.Api.authorization.activeProject.connectors[0].uid;

    //const res = null;
    /* URL PARA CONSUMO DAS CONFIGURAÇÕES */
    //const res = await Sdk.dynamic.bridge(`auth/v1.1/connectors/uid/${connector_uid}`, null, 'GET');

   //setEntity({ ...entity });
  };

  useEffect(() => {
    loadEntities();
    getForm();
    const rawDraft = localStorage.getItem('edit-layout-configuration-draft');
    if (rawDraft) {
      const parsedDraft = JSON.parse(rawDraft);
      setDefaultValues(parsedDraft);
    }
    consumer.defineRoute({ name: 'Configurações de layout', uri: '/settings/layout/edit' });
  }, []);

  useEffect(() => {
    loadEntities();
  }, [entity]);

  const handleSave = async (inputs, image) => {
    let inputsCp = { ...inputs };
    if (image) {
      const base64Image = await toBase64(inputs[image.id]);
      inputsCp[image.id] = base64Image;
    }
    localStorage.setItem('edit-layout-configuration', JSON.stringify(inputsCp));
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });



  async function handleSubmit(data) {
    setLoadingSubmit(true);
    var connectorUid = await Sdk.Api.authorization.activeProject.connectors.filter(connector => connector.type === "Customization_1")[0].uid;
    const result = await Sdk.dynamic.bridge(`auth/v1.1/connector/uid/${connectorUid}`, null, 'GET');

    console.log("result: ", result);


    if (!data.logotype) {
      setLoadingSubmit(false);
      setMsgError(entity?.noThumbMessage);
      return;
    }

    const thumb_url = typeof(data.logotype) === 'object' ? await getThumbUrl(data.logotype) : data.logotype;
      //delete course[entity.image.id];

    const configs = [{
      "uid": "headerBackground",
      "value": data.headerBackground
    },{
      "uid": "sidebarActiveButtonBackgroundColor",
      "value": data.sidebarActiveButtonBackgroundColor
    },{
      "uid": "headerDropdownBackground",
      "value": data.headerDropdownBackground
    },{
      "uid": "headerLogotype",
      "value": thumb_url
    },{
      "uid": "sidebarPosition",
      "value": data.sidebarPosition
    },{
      "uid": "sidebarButtonBackgroundColor",
      "value": data.sidebarButtonBackgroundColor
    },{
      "uid": "sidebarLabelColor",
      "value": data.sidebarLabelColor
    },{
      "uid": "sidebarActiveLabelColor",
      "value": data.sidebarActiveLabelColor
    },{
      "uid": "headerFirstLabelColor",
      "value": data.headerFirstLabelColor
    }
  ];
  

    result["configuration"]["configs"] = configs;
    console.log("result: ", result);
    const body = result["configuration"];
    const layoutConfigs = { ...data };

    try {
      await Sdk.dynamic.bridge(`auth/v1.0/connector/uid/${connectorUid}`, body, 'PUT');

      setLoadingSubmit(false);
      setMsgSuccess('Configurações editadas com sucesso!');
    } catch (error) {
      setLoadingSubmit(false);
      setMsgError('Erro ao editar configurações!');
    }
  }

  const handleSubmit2 = async (inputs, setInputs) => {
    setLoadingSubmit(true);

    try {
      const { connectors } = Sdk.authorization.activeProject;
      const learningConnector = getConnectorFromArray( connectors, 'Learning_1', 'ContentManager');

      if (!learningConnector) return;

      const layout_configuration = { ...inputs };

      if (entity.image) {
        const imageId = entity.image.id;
        const image = inputs[imageId];

        if (!image) {
          setLoadingSubmit(false);
          setMsgError(entity?.noThumbMessage);
          return;
        }
        layout_configuration.thumb_url = await getThumbUrl(image);
        delete layout_configuration[entity.image.id];
      }

      const field = entity.fields.find((item) => item.field === 'entities');
      layout_configuration.entities = Object.keys(inputs[field.id]).filter(
        (key) => inputs[field.id][key]
      );
      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/connector/uid/${learningConnector.uid}`,
        layout_configuration,
        'PUT'
      );


      setMsgSuccess(entity?.createText);

      setInputs({});
    } catch (error) {
      setMsgError(entity?.createError);
    }
    setLoadingSubmit(false);
  };

  async function getThumbUrl(file) {
    const response = await DAMService.uploadFileAxios(
      file,
      null,
      'ContentManager'
    );

    const publicUrl = await DAMService.getPublicUrl(response.data[0].uid);

    return publicUrl;
  }

  return (
    <div>
      <Header />
      <Sidebar
        currentRoute={consumer ? consumer.currentRoute : null}
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
      <div className="main-content">
        {loadingSubmit && <Loading msg="Salvando configurações..." />}
        {!loadingSubmit && msgSuccess && (
          <NotifyBox
            type="success"
            onClose={() => setMsgSuccess('')}
            message={msgSuccess}
          />
        )}
        {!loadingSubmit && msgError && (
          <NotifyBox
            type="error"
            onClose={() => setMsgError('')}
            message={msgError}
          />
        )}
        {entity ? (
          <>
          <DynamicForm
            handleSubmit={handleSubmit}
            form={entity}
            submitText="Salvar configurações"
            defaultValues={defaultValues}
          />
        </>
        ) : (
          <Loading msg="Setting up form..." />
        )}
      </div>
      
      <Footer version="01" />
    </div>
  );
}