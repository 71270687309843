const RDP_CONFIG = {};

RDP_CONFIG.version = 'homolog';
RDP_CONFIG.prodLogin = 'https://api.digitalpages.com.br/';
RDP_CONFIG.prodURL = `https://api.digitalpages.com.br/cms/${RDP_CONFIG.version}/`;
RDP_CONFIG.hmgURL = 'https://api.digitalpages.com.br/cms/homolog/';

RDP_CONFIG.urlStorage = 'https://api.digitalpages.com.br/storage/v1.0/';

RDP_CONFIG.connectorType = 'News_1';
RDP_CONFIG.API_ENV = 'Production';
RDP_CONFIG.customLoginBackground = null;

// change api server
RDP_CONFIG.apiURL = RDP_CONFIG.prodURL;

RDP_CONFIG.configBase64 = null;
RDP_CONFIG.config = null;

export default RDP_CONFIG;
