import React, { Component } from 'react';
import './new-content.scss';

import {
  Header,
  Sidebar,
  Breadcrumb,
  FormDynamic,
  NotifyBox,
} from '../../components';

import { EntityService } from '../../services/entity-content';
import Loading from '../../components/loading';
import { NoticeService } from '../../services/notice';
import { ProductsService } from '../../services/products';
import RDP_UTILS from '../../config/util';

export default class ViewNewContent extends Component {
  constructor(props) {
    super(props);
    const { entityUid, routeUid } = this.props.match.params;
    this.state = {
      entityUid: entityUid,
      routeUid: routeUid,
      ready: false,
      loading: false,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: !entityUid ? 'Módelo do formulário não encontrado.' : null,
    };
  }

  componentDidMount() {
    this.setState({ ready: false, loading: true }, this.getEntity);
  }

  handleChange = ({ id, value }) => {
    const dataEntity = this.state.entity;

    if (this.state.listenValues.includes(id)) {
      try {
        dataEntity.configuration.map(
          async ({
            identifier,
            reference_entity_properties,
            type,
            configuration,
          }) => {
            if (type == 'table') {
              await Promise.all(
                configuration.columns.map(async (column) => {
                  if (column.reference_entity_properties) {
                    if (column.reference_entity_properties.filter_by) {
                      EntityService.getOptionsReference(
                        this.state.entityUid,
                        [identifier, column.identifier].join('->'),
                        {
                          filter: `(${column.reference_entity_properties.filter_by}:${value})`,
                        }
                      ).then((data) => {
                        this.setState({
                          selectOptions: [
                            ...this.state.selectOptions.filter(
                              (option) =>
                                option.identifier !=
                                [identifier, column.identifier].join('->')
                            ),
                            {
                              identifier: [identifier, column.identifier].join(
                                '->'
                              ),
                              data,
                            },
                          ],
                        });
                      });
                    }
                  }
                })
              );
            }

            if (reference_entity_properties) {
              if (reference_entity_properties.filter_by) {
                EntityService.getOptionsReference(
                  this.state.entityUid,
                  identifier,
                  {
                    filter: `(${reference_entity_properties.filter_by}:${value})`,
                  }
                ).then((data) => {
                  this.setState({
                    selectOptions: [
                      ...this.state.selectOptions.filter(
                        (option) => option.identifier != identifier
                      ),
                      {
                        identifier,
                        data,
                      },
                    ],
                  });
                });
              }
            }
          }
        );
      } catch (e) {
        console.log('erorrrr:', e);
      }
    }
  };

  async getEntity() {
    if (this.state.entityUid) {
      let dataEntity = await EntityService.getEntity(this.state.entityUid);
      let products = [];
      let selectOptions = [];
      let listenValues = [];

      try {
        products = await ProductsService.getProductsAvailables();
      } catch (_) {}

      try {
        await Promise.all(
          dataEntity.configuration.map(
            async ({
              identifier,
              reference_entity_properties,
              type,
              configuration,
            }) => {
              if (type == 'table') {
                await Promise.all(
                  configuration.columns.map(async (column) => {
                    if (column.reference_entity_properties) {
                      if (column.reference_entity_properties.filter_by) {
                        listenValues.push(
                          column.reference_entity_properties.filter_by
                        );
                      }

                      selectOptions.push({
                        identifier: [identifier, column.identifier].join('->'),
                        data: await EntityService.getOptionsReference(
                          this.state.entityUid,
                          [identifier, column.identifier].join('->')
                        ),
                      });
                    }
                  })
                );
              }

              if (reference_entity_properties) {
                if (reference_entity_properties.filter_by) {
                  listenValues.push(reference_entity_properties.filter_by);
                }

                selectOptions.push({
                  identifier,
                  data: await EntityService.getOptionsReference(
                    this.state.entityUid,
                    identifier
                  ),
                });
              }
            }
          )
        );
      } catch (e) {
        console.log('erorr:', e);
      }
      this.setState({
        ready: true,
        loading: false,
        entity: dataEntity.configuration ? dataEntity : null,
        productsAvailables: products
          ? RDP_UTILS.formatProductsToSelect(products)
          : [],
        selectOptions,
        listenValues,
      });
    } else {
      this.setState({
        ready: true,
        loading: false,
      });
    }
  }

  async saveEntity(dataForm) {
    let dataNotice = await NoticeService.saveNotice(
      this.state.routeUid,
      dataForm
    );
    let typeAction =
      dataNotice.status === 'Published' ? 'Publicação ' : 'Rascunho ';
    if (dataNotice.status === 'Draft' || dataNotice.status === 'Published') {
      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgSuccess:
          typeAction +
          this.props.consumer.currentRoute.name +
          ' foi salvo com sucesso!',
        notice: dataNotice,
      });
    }
  }

  handleSubmit = (dataForm) => {
    this.setState(
      {
        ready: true,
        loading: false,
        loadingSubmit: true,
      },
      () => this.saveEntity(dataForm)
    );
  };

  returnPath() {
    const url = `/cms/news/route/uid/${this.state.routeUid}`;
    return url;
  }

  resetMsg = () => {
    this.setState({ msgSuccess: null, msgError: null });
  };

  render() {
    const { consumer } = this.props;
    const {
      entity,
      ready,
      loading,
      loadingSubmit,
      msgSuccess,
      msgError,
    } = this.state;

    return (
      <div className="rdp-admin-new-entity">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb
          backRoute={this.returnPath()}
          currentRoute={consumer ? consumer.currentRoute : null}
        />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loadingSubmit && <Loading msg="Enviando..." />}
          {!loadingSubmit && msgSuccess && (
            <NotifyBox
              type="success"
              onClose={this.resetMsg}
              message={msgSuccess}
            />
          )}
          {!loadingSubmit && msgError && (
            <NotifyBox
              type="error"
              onClose={this.resetMsg}
              message={msgError}
            />
          )}
          {loading && <Loading msg="Carregando formulário..." />}
          {ready && !loading && entity && (
            <FormDynamic
              blocked={msgSuccess || msgError ? true : false}
              isNew={true}
              cleanValues={msgSuccess ? true : false}
              handleSubmit={this.handleSubmit}
              selectOptions={this.state.selectOptions}
              entity={entity}
              productsAvailables={this.state.productsAvailables}
              handleChange={this.handleChange}
            />
          )}
        </div>
      </div>
    );
  }
}
