import React, { Component } from 'react';
import './loading-fullscreen.scss';

export default class LoadingFullscreen extends Component {
  render() {
    const {
      renderType = "success",
      uploadingMessage = "Realizando upload...",
      successMessage = "Upload realizado com sucesso",
      successBtnLabel = "Atualizar listagem de usuários",
      successBtnOnClick = function(){},
      errorMessage = "Erro ao realizar upload",
      errorBtnLabel = "Fechar e tentar novamente",
      errorBtnOnClick = function(){}
    } = this.props;
    return <div className="rdp-admin-component-loading-fullscreen">
      <div className="rdp-admin-component-loading-fullscreen-overlay"></div>
      <div className="rdp-admin-component-loading-fullscreen-modal">
        {renderType === "uploading" &&
          <>
            <span className="rdp-admin-component-loading-fullscreen-icon uploading"></span>
            <span className="rdp-admin-component-loading-fullscreen-message" dangerouslySetInnerHTML={{ __html: uploadingMessage}}></span>
            <div className="rdp-admin-component-loading-fullscreen-preloader"></div>
          </>
        }
        {renderType === "success" &&
          <>
            <span className="rdp-admin-component-loading-fullscreen-icon success"></span>
            <span className="rdp-admin-component-loading-fullscreen-message" dangerouslySetInnerHTML={{ __html: successMessage}}></span>
            <button className="rdp-admin-component-loading-fullscreen-success-btn" onClick={() => successBtnOnClick()}>
              {successBtnLabel}
            </button>
          </>
        }
        {renderType === "error" &&
          <>
            <span className="rdp-admin-component-loading-fullscreen-icon error"></span>
            <span className="rdp-admin-component-loading-fullscreen-message" dangerouslySetInnerHTML={{ __html: errorMessage}}></span>
            <button className="rdp-admin-component-loading-fullscreen-error-btn" onClick={() => errorBtnOnClick()}>
              {errorBtnLabel}
            </button>
          </>
        }
      </div>
    </div>
  }
}