const Configuration = {
  getEntity: () => ({
    layout_configuration : {
      hide_event_period : true,
      hide_products_restriction : true
    },
    configuration : [
      {
        field: "Título do curso",
        identifier: "title",
        type: "input-text",
      },
      {
        configuration: null,
        field: null,
        badge: true,
        column: "main",
        hidden: false,
        identifier: "nodes",
        reference_entity_properties: null,
        required: false,
        search_column: true,
        type: "hierarchy",
        group: "Nós",
        data : [],
        fields_configuration: []
      },
      { 
        identifier: "members",
        field: null,
        group: "Usuários",
        type: "table",
        configuration: {
          hidden_index: true,
          hidden_move: true,
          columns: [
            {
              identifier: "user",
              field: "Usuários",
              type: "reference-entity",
              reference_entity_properties: {
                options_fields: [
                  {
                    identifier: "name",
                    type: "input-text"
                  }
                ]
              }
            },
            {
              identifier: "role",
              field: "Cargos",
              type: "reference-entity",
              options: {
                multiple: true
              },
              reference_entity_properties: {
                options_fields: [
                  {
                    identifier: "name",
                    type: "input-text"
                  }
                ]
              }
            }
          ]
        }
      },
    ]
  })
};

export default Configuration;
